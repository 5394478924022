import { formUrlQuery } from "./format-queries";

export const handleAttachQuery = (searchState, productListPageState) => {
  let queries = {};

  if (
    searchState &&
    searchState.currencyVal &&
    searchState.currencyVal.id &&
    searchState.currencyVal.id !== 32
  ) {
    queries.currency_id = searchState.currencyVal.id;
  }

  if (searchState && searchState.category && searchState.category.id) {
    queries.category_id = searchState.category.id;
  }

  if (searchState && searchState.subCategory && searchState.subCategory.id) {
    queries.sub_category_id = searchState.subCategory.id;
  }

  if (searchState && searchState.otherFilter) {
    let sortByFilter =
      searchState.otherFilter && searchState.otherFilter.sortBy;
    if (
      sortByFilter &&
      Array.isArray(sortByFilter) &&
      sortByFilter.length > 0
    ) {
      sortByFilter.forEach((other) => {
        const { label, isSelected } = other;
        if (isSelected) {
          if (label === "Price (High to low)") {
            queries.price = "DESC";
          } else if (label === "Price (Low to High)") {
            queries.price = "ASC";
          } else if (label === "Product name A-Z") {
            queries.name = "ASC";
          } else if (label === "Product name Z-A") {
            queries.name = "DESC";
          } else if (label === "Sale (Discounted products)") {
            queries.discounted_products = true;
          } else if (label === "Newest added") {
            queries.createdAt = "DESC";
          }
          // if (label === "Category name A-Z") {
          //   queries.category_name = "ASC";
          // }
          // if (label === "Category name Z-A") {
          //   queries.category_name = "DESC";
          // }
          // if (label === "Sub-Category name A-Z") {
          //   queries.sub_category_name = "ASC";
          // }
          // if (label === "Sub-Category name Z-A") {
          //   queries.sub_category_name = "DESC";
          // }
          // if (label === "Brand name A-Z") {
          //   queries.brand_name = "ASC";
          // }
          // if (label === "Brand name Z-A") {
          //   queries.brand_name = "DESC";
          // }
        }
      });
    }

    let genderFilter =
      searchState.otherFilter && searchState.otherFilter.gender;
    if (genderFilter) {
      queries.gender = genderFilter;
    }

    let brandNames =
      searchState.otherFilter && searchState.otherFilter.brandNames;
    if (brandNames && Array.isArray(brandNames) && brandNames.length > 0) {
      queries.brand_names = brandNames;
    }

    let prices = searchState.otherFilter && searchState.otherFilter.prices;
    if (prices && Array.isArray(prices) && prices.length > 0) {
      let finalPrices = prices;
      finalPrices = finalPrices.map((price) => {
        const { minPrice, maxPrice } = price;
        return `${minPrice} - ${maxPrice}`;
      });
      queries.prices = finalPrices;
    }
  }

  if (productListPageState && productListPageState.limit) {
    queries.limit = productListPageState.limit;
  }

  if (productListPageState && productListPageState.offset) {
    queries.offset = productListPageState.offset;
  } else {
    const { currentPage, limit } = productListPageState || {};
    queries.offset = (currentPage - 1) * limit;
  }

  return formUrlQuery(queries);
};
