import React from "react";
import { useSelector } from "react-redux";

// Styles
import "../../../styles/items.css";

const ItemHeader = ({ products, skeletonLoader }) => {
  const searchState = useSelector((state) => state.searchState);

  return (
    <div className="itemsHeaderContainer">
      {skeletonLoader ? (
        <div className="itemsHeaderTitleAndResult">
          <div className="loading-btn w-24 md:w-40 w-60 h-8 md:h-10 lg:h-12 rounded-xl"></div>
          <div className="loading-btn w-16 md:w-24 lg:w-24 h-5 md:h-6 lg:h-6 rounded-xl"></div>
        </div>
      ) : (
        <div className="itemsHeaderTitleAndResult">
          <div className="itemsHeaderTitle">
            {searchState && searchState.category && searchState.category.name
              ? searchState.category.name
              : "All Products"}
          </div>
          <div className="itemsHeaderResult">
            {products && Array.isArray(products) && products.length > 1
              ? `${products.length} Results`
              : products && Array.isArray(products) && products.length === 1
              ? `${products.length} Result`
              : `0 Result`}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemHeader;
