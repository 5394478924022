import React, { useEffect, useState } from "react";

// Images and Icons
import CouponIcon from "../../../assets/myProfileIcons/CouponIcon.png";
import EmptyWishlistIcon from "../../../assets/myProfileIcons/EmptyWishlistIcon.png";

// Helpers
import { handleGetStringStartDateToEndDate } from "../../../helpers/dateHandler";

// Apis
import { fetchAllPromo } from "../../../api/promos";

const MyPromoCodeTab = ({ isMobileSize, dispatch }) => {
  const [isOpenExpiredCouponTab, setIsOpenExpiredCouponTab] = useState(false);
  const [promoCodeTabs, setPromoCodeTabs] = useState([
    {
      label: "Unused",
      isOpenTab: false,
      couponList: [],
    },
    {
      label: "Expired coupon",
      isOpenTab: false,
      couponList: [],
    },
  ]);
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    handleFetchAllPromo();
  }, []);

  const handleFetchAllPromo = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await fetchAllPromo();
    if (result && result.data) {
      const { data } = result || {};
      let unUsedCouponArr =
        data && Array.isArray(data) && data.length > 0
          ? data.filter((el) => el.is_active === true)
          : [];
      let expiredCouponArr =
        data && Array.isArray(data) && data.length > 0
          ? data.filter((el) => el.is_active === false)
          : [];
      let finalPromoCodeTabs = promoCodeTabs;
      finalPromoCodeTabs = finalPromoCodeTabs.map((promoCode) => {
        const { label } = promoCode;
        if (label === "Unused") {
          promoCode.couponList = unUsedCouponArr;
        } else {
          promoCode.couponList = expiredCouponArr;
        }
        return promoCode;
      });
      setPromoCodeTabs(finalPromoCodeTabs);
      handleOnClickPromoCodeTab(
        isOpenExpiredCouponTab ? "Expired coupon" : "Unused"
      );
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  useEffect(() => {
    handleOnClickPromoCodeTab(
      isOpenExpiredCouponTab ? "Expired coupon" : "Unused"
    );
  }, [isOpenExpiredCouponTab]);

  const handleOnClickPromoCodeTab = (flagger) => {
    if (
      promoCodeTabs &&
      Array.isArray(promoCodeTabs) &&
      promoCodeTabs.length > 0
    ) {
      let finalPromoCodeTabs = promoCodeTabs;
      finalPromoCodeTabs = finalPromoCodeTabs.map((tab) => {
        const { label, couponList } = tab || {};
        if (flagger === label) {
          setIsOpenExpiredCouponTab(label === "Expired coupon");
          tab = { ...tab, isOpenTab: true };
          setCoupons(couponList);
        } else {
          tab = { ...tab, isOpenTab: false };
        }
        return tab;
      });
      setPromoCodeTabs(finalPromoCodeTabs);
    }
  };

  return (
    <div className="tabMainContainer">
      {!isMobileSize ? (
        <div className="tabNameContainer" style={{ marginBottom: "2em" }}>
          <div className="tabNameStyle">Promo code</div>
        </div>
      ) : null}
      {promoCodeTabs &&
        Array.isArray(promoCodeTabs) &&
        promoCodeTabs.length > 0 && (
          <div className="promoCodeTabsMainContainer">
            {promoCodeTabs.map((promoCodeTab, index) => {
              const { label, isOpenTab } = promoCodeTab || {};
              return (
                <div
                  key={index}
                  className="eachPromoCodeTabBoxContainer"
                  style={{
                    borderBottom: isOpenTab ? "2px solid #525252" : "",
                  }}
                  onClick={() => handleOnClickPromoCodeTab(label)}
                >
                  <div
                    style={{
                      color: isOpenTab ? "#000" : "#9E9E9E",
                    }}
                  >
                    {label}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      {coupons && Array.isArray(coupons) && coupons.length > 0 ? (
        <div
          style={{
            paddingTop: "0.5em",
            paddingBottom: "1em",
          }}
        >
          {coupons.map((coupon, index) => {
            const { title, expired_start_date, expired_end_date, promo_code } =
              coupon || {};
            const couponDate = handleGetStringStartDateToEndDate(
              expired_start_date,
              expired_end_date
            );
            return (
              <div key={index} className="eachCouponMainContainer">
                <div className="eachCouponContentContainer">
                  <div>
                    <img src={CouponIcon} alt="coupon-icon" />
                  </div>
                  <div className="eachCouponDescContainer">
                    <div
                      className="eachCouponDesc"
                      style={{ fontWeight: "700", color: "#000" }}
                    >
                      {title}
                    </div>
                    <div
                      className="eachCouponDesc"
                      style={{ fontWeight: "400", color: "#9E9E9E" }}
                    >
                      {(expired_start_date && expired_end_date) ? couponDate : "No expired date"}
                    </div>
                    <div
                      className="eachCouponDesc"
                      style={{ fontWeight: "400", color: "#000" }}
                    >
                      {promo_code}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="emptyWishlistContainer">
          <img src={EmptyWishlistIcon} alt="empty-wishlist-icon" />
          <div>
            {isOpenExpiredCouponTab
              ? "No expired promo code yet"
              : "No unused promo code yet"}
          </div>
        </div>
      )}
    </div>
  );
};

export default MyPromoCodeTab;
