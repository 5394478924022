import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Styles
import "../../styles/items.css";

// Components
import ItemHeader from "./itemComponents/ItemHeader";
import SidebarFilter from "./itemComponents/SidebarFilter";
import ItemList from "./itemComponents/ItemList";
import HideOnScroll from "../../components/HideOnScroll";

// Helpers
import { handleFetchAllProducts } from "../../helpers/handleFetchAllProducts";
import { formatPrice } from "../../helpers/format-price";

// Apis
import { getAllProductSubCategories } from "../../api/productSubCategories";
import { getAllProductBrands } from "../../api/productBrands";
import { handleAttachQuery } from "../../helpers/attach-queries";

import ArrowRightIcon from "../../assets/itemImagesAndIcons/ArrowRightIcon.png"

const Items = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramsOffset = queryParams.get("offset");
  const paramsLimit = queryParams.get("limit");
  const paramsSortByPrice = queryParams.get("price");
  const paramsSortByProductName = queryParams.get("name");
  const paramsSortByDiscountedProduct = queryParams.get("discounted_products");
  const paramsSortByNewestAdded = queryParams.get("createdAt");
  const paramsFilterByGenders = queryParams.get("gender");
  const paramsFilterByPrices = queryParams.get("prices");
  const paramsFilterByBrandNames = queryParams.get("brand_names");
  const paramsFilterByColors = queryParams.get("colors");

  const searchState = useSelector((state) => state.searchState);
  const products = useSelector((state) => state.products);
  const productListPageState = useSelector(
    (state) => state.productListPageState
  );
  const isMobileSize = useSelector((state) => state.isMobileSize);
  const skeletonLoader = useSelector((state) => state.skeletonLoader);

  const [subCategories, setSubCategories] = useState([]);
  const [sidebarFilterVal, setSidebarFilterVal] = useState([
    {
      optionName: "Sort by",
      optionValue: [
        { label: "Price (High to low)", isSelected: false },
        { label: "Price (Low to High)", isSelected: false },
        { label: "Product name A-Z", isSelected: false },
        { label: "Product name Z-A", isSelected: false },
        { label: "Discounted products", isSelected: false },
        { label: "Newest added", isSelected: false },
        // { label: "Category name A-Z", isSelected: false },
        // { label: "Category name Z-A", isSelected: false },
        // { label: "Sub-Category name A-Z", isSelected: false },
        // { label: "Sub-Category name Z-A", isSelected: false },
        // { label: "Brand name A-Z", isSelected: false },
        // { label: "Brand name Z-A", isSelected: false },
      ],
      isOpenOption: false,
    },
    {
      optionName: "Gender",
      optionValue: [
        { label: "Male", isSelected: false },
        { label: "Female", isSelected: false },
        { label: "Unisex", isSelected: false },
      ],
      isOpenOption: false,
    },
    {
      optionName: "Brand",
      optionValue: [],
      isOpenOption: false,
    },
    {
      optionName: "Color",
      optionValue: [
        { label: "Beige", isSelected: false },
        { label: "Black", isSelected: false },
        { label: "White", isSelected: false },
        { label: "Blue", isSelected: false },
        { label: "Pink", isSelected: false },
        { label: "Purple", isSelected: false },
        { label: "Brown", isSelected: false },
        { label: "Navy", isSelected: false },
      ],
      isOpenOption: false,
    },
    {
      optionName: "Price",
      optionValue: [
        { label: { minPrice: 0, maxPrice: 100000 }, isSelected: false },
        { label: { minPrice: 100000, maxPrice: 200000 }, isSelected: false },
        { label: { minPrice: 200000, maxPrice: 500000 }, isSelected: false },
        { label: { minPrice: "Above", maxPrice: 500000 }, isSelected: false },
      ],
      isOpenOption: false,
    },
  ]);
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  const handleFormatSubCategories = (state) => {
    let finalSubCategories = state.subCategories;
    finalSubCategories =
      finalSubCategories &&
        Array.isArray(finalSubCategories) &&
        finalSubCategories.length > 0
        ? finalSubCategories.map((subCategory) => {
          if (
            state &&
            state.subCategory &&
            state.subCategory.id &&
            state.subCategory.id === subCategory.id
          ) {
            subCategory.selected = true;
          } else {
            subCategory.selected = false;
          }
          return subCategory;
        })
        : [];
    setSubCategories(finalSubCategories);
  };

  const sliderRef = useRef(null);

  useEffect(() => {
    if (
      searchState &&
      searchState.subCategories &&
      Array.isArray(searchState.subCategories) &&
      searchState.subCategories.length > 0
    ) {
      handleFormatSubCategories(searchState);
    } else {
      fetchAllSubCategories();
    }
  }, [searchState]);

  const fetchAllProducts = async (searchState, productListPageState) => {
    dispatch({
      type: "SET_SKELETON_LOADER",
      skeletonLoader: true,
    });
    let queries = handleAttachQuery(
      { ...searchState },
      {
        ...productListPageState,
      }
    );
    const result = await handleFetchAllProducts(queries);
    const totalProductCount = await handleFetchAllProducts(queries, true);
    if (result) {
      dispatch({
        type: "SET_PRODUCTS",
        products: result,
      });
    }
    if (totalProductCount && totalProductCount.total_count) {
      const { total_count } = totalProductCount || {};
      let finalTotalPage = Math.ceil(total_count / productListPageState.limit);
      dispatch({
        type: "SET_PRODUCT_LIST_PAGE_STATE",
        productListPageState: {
          ...productListPageState,
          totalPages: finalTotalPage,
        },
      });
    }
    dispatch({
      type: "SET_SKELETON_LOADER",
      skeletonLoader: false,
    });
  };

  const fetchAllSubCategories = async () => {
    try {
      const result = await getAllProductSubCategories();
      if (result && result.data) {
        const { data } = result || {};
        if (data && Array.isArray(data) && data.length > 0) {
          let finalSubCategories = data.map((sub_category) => {
            sub_category.selected = false;
            return sub_category;
          });
          setSubCategories(finalSubCategories);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleOnClickSubCategory = (selectedSubCategory, value) => {
    let finalSubCategories = subCategories;
    finalSubCategories = finalSubCategories.map((subCategory) => {
      const { name } = subCategory;
      if (name === selectedSubCategory.name) {
        subCategory.selected = value;
      } else {
        subCategory.selected = false;
      }
      return subCategory;
    });
    setSubCategories(finalSubCategories);
    dispatch({
      type: "SET_SEARCH_STATE",
      searchState: {
        ...searchState,
        subCategory: value ? selectedSubCategory : null,
        subCategories: finalSubCategories,
      },
    });
    const urlQueries = handleAttachQuery(
      {
        ...searchState,
        subCategory: value ? selectedSubCategory : null,
        subCategories: finalSubCategories,
      },
      productListPageState
    );
    navigate(`/items?${urlQueries}`);
  };

  const handleApplyFilter = (sidebarFilterVal) => {
    let filterValue = {
      sortBy: [],
      brandNames: [],
      prices: [],
      gender: [],
    };
    for (let s = 0; s < sidebarFilterVal.length; s++) {
      const { optionName, optionValue } = sidebarFilterVal[s];
      for (let o = 0; o < optionValue.length; o++) {
        const { isSelected } = optionValue[o];
        if (optionName === "Sort by") {
          if (isSelected) {
            filterValue.sortBy.push(optionValue[o]);
          }
        }
        if (optionName === "Brand") {
          if (isSelected) {
            filterValue.brandNames.push(optionValue[o].name);
          }
        }
        if (optionName === "Price") {
          if (isSelected) {
            filterValue.prices.push(optionValue[o].label);
          }
        }
        if (optionName === "Gender") {
          if (isSelected) {
            let lowercaseGender = optionValue[o].label.toLowerCase();
            filterValue.gender.push(lowercaseGender);
          }
        }
      }
    }
    let finalSearchState = {
      ...searchState,
      otherFilter: filterValue,
    };
    dispatch({
      type: "SET_SEARCH_STATE",
      searchState: finalSearchState,
    });
    const urlQueries = handleAttachQuery(
      finalSearchState,
      productListPageState
    );
    navigate(`/items?${urlQueries}`);
  };

  const handleOnClickInputSidebarFilter = (optionKey, selectedOpt, checked) => {
    let finalSidebarFilterVal = sidebarFilterVal;
    finalSidebarFilterVal = finalSidebarFilterVal.map((sidebarFilterValue) => {
      const { optionName, optionValue } = sidebarFilterValue || {};
      if (optionKey === optionName) {
        optionValue.map((optVal) => {
          const { label, label: { minPrice, maxPrice } = {} } = optVal || {};
          if (minPrice && maxPrice) {
            // for price filter
            if (
              minPrice === selectedOpt.label.minPrice &&
              maxPrice === selectedOpt.label.maxPrice
            ) {
              optVal.isSelected = checked;
            }
          } else {
            if (label === selectedOpt.label) {
              optVal.isSelected = checked;
            } else {
              if (optionName === "Sort by") {
                optVal.isSelected = false;
              }
            }
          }
          return optVal;
        });
      }
      return sidebarFilterValue;
    });
    setSidebarFilterVal(finalSidebarFilterVal);
    handleApplyFilter(finalSidebarFilterVal);
  };

  const handleOnClickUnselectAllInput = (optionKey) => {
    let finalSidebarFilterVal = sidebarFilterVal;
    finalSidebarFilterVal = finalSidebarFilterVal.map((sidebarFilterValue) => {
      const { optionName, optionValue } = sidebarFilterValue || {};
      if (optionKey === optionName) {
        optionValue.map((optVal) => {
          optVal.isSelected = false;
          return optVal;
        });
      }
      return sidebarFilterValue;
    });
    setSidebarFilterVal(finalSidebarFilterVal);
    handleApplyFilter(finalSidebarFilterVal);
  };

  const handleOnClickArrowIcon = (optionKey) => {
    let finalSidebarFilterVal = sidebarFilterVal;
    finalSidebarFilterVal = finalSidebarFilterVal.map((sidebarFilterValue) => {
      const { optionName, isOpenOption } = sidebarFilterValue || {};
      if (optionKey === optionName) {
        sidebarFilterValue.isOpenOption = !isOpenOption;
      }
      return sidebarFilterValue;
    });
    setSidebarFilterVal(finalSidebarFilterVal);
  };

  const handleOnChangePage = async (pageValue) => {
    const limit = paramsLimit;
    const offset = (pageValue - 1) * limit;
    dispatch({
      type: "SET_PRODUCT_LIST_PAGE_STATE",
      productListPageState: {
        ...productListPageState,
        offset,
        currentPage: pageValue,
      },
    });
    const queries = handleAttachQuery(searchState, {
      ...productListPageState,
      offset,
      currentPage: pageValue,
    });
    navigate(`/items?${queries}`);
    window.scrollTo(0,0)
  };

  const handleFetchProductBrands = async () => {
    const result = await getAllProductBrands();
    if (result && result.data) {
      const { data } = result || {};
      let finalSidebarFilterVal = sidebarFilterVal;
      finalSidebarFilterVal = finalSidebarFilterVal.map((sidebarFilter) => {
        const { optionName } = sidebarFilter || {};
        const arrBrandNames =
          (searchState &&
            searchState.otherFilter &&
            searchState.otherFilter.brandNames) ||
          [];
        let finalData = data;
        finalData = finalData.map((final) => {
          const { name } = final || {};
          final.label = name;
          // set input checkbox to true if there's brand filter from search value state from reducer
          let alreadyExist = arrBrandNames.some((brandName) => {
            return brandName === name;
          });
          final.isSelected = alreadyExist;
          return final;
        });
        if (optionName === "Brand") {
          sidebarFilter.optionValue = finalData;
        }
        return sidebarFilter;
      });
      setSidebarFilterVal(finalSidebarFilterVal);
    }
  };

  useEffect(() => {
    fetchAllProducts(searchState, productListPageState);
    handleAssignValueForSidebarFilter(searchState, productListPageState);
    handleFetchProductBrands();
  }, [
    searchState,
    paramsOffset,
    paramsLimit,
    paramsSortByPrice,
    paramsSortByProductName,
    paramsSortByDiscountedProduct,
    paramsSortByNewestAdded,
    paramsFilterByGenders,
    paramsFilterByPrices,
    paramsFilterByBrandNames,
    paramsFilterByColors,
  ]);

  const handleAssignValueForSidebarFilter = (
    searchState,
    productListPageState
  ) => {
    navigate({
      pathname: "/items",
      search: handleAttachQuery(searchState, productListPageState),
    });
  };

  const SliderNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <img
        className={className}
        src={ArrowRightIcon}
        alt=">"
        onClick={onClick}
        style={{ ...style, width: 12, height: 12 }}
      />
    );
  }

  const settingSlider = {
    dots: false,
    infinite: false,
    slidesToShow: isMobileSize ? 3 : 6,
    slidesToScroll: isMobileSize ? 3 : 6,
    rows: isMobileSize ? 1 : subCategories && subCategories.length > 6 ? 2 : 1,
    nextArrow: <SliderNextArrow />,
  };

  return (
    <div className="itemsMainContainer">
      <div className="itemHeaderAndSideFilterContainer">
        <HideOnScroll>
          <ItemHeader products={products} skeletonLoader={skeletonLoader} />
        </HideOnScroll>
        {skeletonLoader ? (
          <div className="itemsHeaderSlider">
            <Slider {...settingSlider}>
              {
                Array.from({ length: 8 }).map(() => {
                  return (
                    <div className="itemsHeaderItemChilds">
                      <div className="eachItemChild loading-btn w-12 md:w-16 lg:w-full h-4 md:h-6 lg:h-9 bg-slate-300 rounded-xl"></div>
                    </div>
                  )
                })
              }
            </Slider>
          </div>
        ) : (
          <div className="itemsHeaderSlider">
            <Slider ref={sliderRef} {...settingSlider}>
              {subCategories &&
                Array.isArray(subCategories) &&
                subCategories.length > 0 &&
                subCategories.map((subCategory, index) => {
                  const { name, selected } = subCategory || {};
                  return (
                    <div className="itemsHeaderItemChilds">
                      <div
                        key={index}
                        className="eachItemChild"
                        style={{
                          backgroundColor: selected ? "#006787" : "",
                          border: selected ? "" : "1px solid #525252",
                        }}
                        onClick={() =>
                          handleOnClickSubCategory(subCategory, !selected)
                        }
                      >
                        <div style={{ color: selected ? "#FFFFFF" : "#006787" }}>
                          {name}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Slider>
          </div>
        )}
      </div>
      <div className="itemsSidebarAndContentContainer">
        <SidebarFilter
          sidebarFilterVal={sidebarFilterVal}
          handleOnClickInputSidebarFilter={handleOnClickInputSidebarFilter}
          handleOnClickUnselectAllInput={handleOnClickUnselectAllInput}
          handleOnClickArrowIcon={handleOnClickArrowIcon}
          setShowFilterOptions={setShowFilterOptions}
          showFilterOptions={showFilterOptions}
          isMobileSize={isMobileSize}
          skeletonLoader={skeletonLoader}
        />
        <ItemList
          products={products}
          searchState={searchState}
          productListPageState={productListPageState}
          handleOnChangePage={handleOnChangePage}
          sidebarFilterVal={sidebarFilterVal}
          handleOnClickInputSidebarFilter={handleOnClickInputSidebarFilter}
          handleOnClickArrowIcon={handleOnClickArrowIcon}
          setShowFilterOptions={setShowFilterOptions}
          showFilterOptions={showFilterOptions}
          isMobileSize={isMobileSize}
          formatPrice={formatPrice}
          skeletonLoader={skeletonLoader}
        />
      </div>
    </div>
  );
};

export default Items;
