import React from "react";

// Images and Icons
import ArrowDownIcon from "../../../assets/navbarIcons/ArrowDownIcon.png";
import ArrowUpIcon from "../../../assets/navbarIcons/ArrowUpIcon.png";
import CloseFullscreenBtn from "../../../assets/itemImagesAndIcons/CloseFullscreenBtn.png";
import FilterIcon from "../../../assets/itemImagesAndIcons/FilterIcon.png";

// Styles
import "../../../styles/items.css";

const SidebarFilter = (props) => {
  const {
    sidebarFilterVal,
    handleOnClickInputSidebarFilter,
    showFilterOptions,
    setShowFilterOptions,
    handleOnClickUnselectAllInput,
    handleOnClickArrowIcon,
    isMobileSize,
    skeletonLoader,
  } = props;

  return (
    <div className="leftFilterBox">
      <div
        className="leftFilterBoxTitleLabel"
        style={
          isMobileSize
            ? {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }
            : {}
        }
      >
        {skeletonLoader ? (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => (isMobileSize ? setShowFilterOptions(true) : null)}
          >
            <div
              className={`eachItemChild loading-btn cif${
                isMobileSize ? "w-20" : "w-12"
              } md:w-16 lg:w-32 ${
                isMobileSize ? "h-6" : "h-4"
              } md:h-6 lg:h-9 bg-slate-300 rounded-xl`}
            ></div>
          </div>
        ) : (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => (isMobileSize ? setShowFilterOptions(true) : null)}
          >
            {isMobileSize ? (
              <img
                src={FilterIcon}
                alt="filter-icon"
                width={15}
                height={15}
                style={{ marginRight: "0.5em" }}
              />
            ) : null}
            <div>Filters</div>
          </div>
        )}
        {isMobileSize && showFilterOptions && (
          <div className="closeBtn" onClick={() => setShowFilterOptions(false)}>
            <img
              src={CloseFullscreenBtn}
              alt="close-fullscreen-btn"
              width={12}
              height={12}
              style={{ marginRight: "0.2em" }}
            />
          </div>
        )}
      </div>
      {(!isMobileSize || (isMobileSize && showFilterOptions)) && (
        <div className="leftFilterBoxOptions">
          {skeletonLoader ? (
            <div
              style={{
                marginLeft: "-0.8vw",
                display: "flex",
                flexDirection: "column",
                gap: "1vh",
              }}
            >
              <div className="leftFilterBoxEachOption loading-btn md:w-60 lg:w-80 h-2 md:h-4 lg:h-7 animate-pulse bg-slate-300 rounded-xl"></div>
              <div className="leftFilterBoxEachOption loading-btn md:w-60 lg:w-80 h-2 md:h-4 lg:h-7 animate-pulse bg-slate-300 rounded-xl"></div>
              <div className="leftFilterBoxEachOption loading-btn md:w-60 lg:w-80 h-2 md:h-4 lg:h-7 animate-pulse bg-slate-300 rounded-xl"></div>
              <div className="leftFilterBoxEachOption loading-btn md:w-60 lg:w-80 h-2 md:h-4 lg:h-7 animate-pulse bg-slate-300 rounded-xl"></div>
              <div className="leftFilterBoxEachOption loading-btn md:w-60 lg:w-80 h-2 md:h-4 lg:h-7 animate-pulse bg-slate-300 rounded-xl"></div>
              <div className="leftFilterBoxEachOption loading-btn md:w-60 lg:w-80 h-2 md:h-4 lg:h-7 animate-pulse bg-slate-300 rounded-xl"></div>
            </div>
          ) : !skeletonLoader &&
            sidebarFilterVal &&
            Array.isArray(sidebarFilterVal) &&
            sidebarFilterVal.length > 0 ? (
            sidebarFilterVal.map((sidebarFilter, index) => {
              const { optionName, optionValue, isOpenOption } = sidebarFilter;
              if (
                optionValue &&
                Array.isArray(optionValue) &&
                optionValue.length > 0
              ) {
                let isFirstOption = index === 0 ? true : false;
                let checkAllOptValue = optionValue.filter(
                  (optVal) => optVal.isSelected === true
                );
                let isThereSelectedFilter =
                  checkAllOptValue &&
                  Array.isArray(checkAllOptValue) &&
                  checkAllOptValue.length > 0
                    ? true
                    : false;
                let finalOptionValue = optionValue;
                if (optionName !== "Sort by") {
                  return (
                    <div
                      key={index}
                      style={
                        isFirstOption
                          ? {
                              borderTop: "1px solid #D9D9D9",
                              borderBottom: "1px solid #D9D9D9",
                            }
                          : { borderBottom: "1px solid #D9D9D9" }
                      }
                    >
                      <div className="leftFilterBoxEachOption">
                        <div
                          className="leftFilterBoxOptionTitle"
                          onClick={() => handleOnClickArrowIcon(optionName)}
                        >
                          {optionName}
                        </div>
                        <div className="leftFilterBoxUnselectAndArrowIconContainer">
                          {isThereSelectedFilter ? (
                            <div
                              onClick={() =>
                                handleOnClickUnselectAllInput(optionName)
                              }
                            >
                              Unselect all
                            </div>
                          ) : null}
                          <img
                            src={isOpenOption ? ArrowDownIcon : ArrowUpIcon}
                            alt={
                              isOpenOption ? "arrow-down-icon" : "arrow-up-icon"
                            }
                            className="leftFilterDropdownIcon"
                            onClick={() => handleOnClickArrowIcon(optionName)}
                          ></img>
                        </div>
                      </div>
                      {isOpenOption ? (
                        <div className="leftFilterCheckboxContainer">
                          {finalOptionValue.map((optionVal, idx) => {
                            const { label, isSelected } = optionVal;
                            return (
                              <div className="divInputCheckbox" key={idx}>
                                <input
                                  type="checkbox"
                                  onChange={({ target: { checked } }) =>
                                    handleOnClickInputSidebarFilter(
                                      optionName,
                                      optionVal,
                                      checked
                                    )
                                  }
                                  checked={isSelected}
                                />
                                {label &&
                                (label.minPrice ||
                                  label.minPrice === 0 ||
                                  label.minPrice === "0") &&
                                label.maxPrice ? (
                                  <div>{`${label.minPrice.toLocaleString(
                                    "id-ID"
                                  )} ${
                                    label.minPrice === "Above" ? "" : "-"
                                  } ${label.maxPrice.toLocaleString(
                                    "id-ID"
                                  )}`}</div>
                                ) : (
                                  <div>{label}</div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                  );
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })
          ) : null}
        </div>
      )}
    </div>
  );
};

export default SidebarFilter;
