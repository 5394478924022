// Core UI Icons
import * as icon from "@coreui/icons";
import CIcon from "@coreui/icons-react";

export const createCountriesOptions = (countries, isMobileSize) => {
  const newCountries = countries.map((country) => {
    const { name, country_code, phone_code } = country;
    let countryIcon = icon[`cif${country_code}`];
    const objCountry = {
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: isMobileSize ? "3vw" : "1vw",
          }}
        >
          <div
            style={{
              width: isMobileSize ? "6vw" : "1.7vw",
            }}
          >
            <CIcon icon={countryIcon} size="sm" />
          </div>
          <div className="truncate" style={{ maxWidth: "15.5vw" }}>
            {name}
          </div>
          {phone_code ? <div>{`(${phone_code})`}</div> : null}
        </div>
      ),
      icon: (
        <CIcon icon={countryIcon} size="sm" height={isMobileSize ? 16 : 24} width={isMobileSize ? 16 : 24} />
      ),
      value: country,
      selectedKey: country.id,
    };
    return objCountry;
  });
  if (newCountries && Array.isArray(newCountries) && newCountries.length > 0) {
    return newCountries;
  }
};
