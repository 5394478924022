import Axios from "../../src/helpers/axios";

export const getAllCountries = () => {
  return Axios.get("/api/ecommerce/v1/countries");
};

export const getProvinces = () => {
  return Axios.get("/api/ecommerce/v1/countries/provinces");
};

export const getCities = (provinceId) => {
  return Axios.get(`/api/ecommerce/v1/countries/cities/${provinceId}`);
};

export const getSubDistricts = (cityId) => {
  return Axios.get(`/api/ecommerce/v1/countries/subdistricts/${cityId}`);
};

export const getDetailProvince = (provinceId) => {
  return Axios.get(
    `/api/ecommerce/v1/countries/provinces/details/${provinceId}`
  );
};

export const getDetailCity = (cityId) => {
  return Axios.get(`/api/ecommerce/v1/countries/cities/details/${cityId}`);
};

export const getDetailSubDistrict = (subDistrictId) => {
  return Axios.get(`/api/ecommerce/v1/countries/subdistricts/details/${subDistrictId}`);
};
