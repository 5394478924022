import moment from "moment";

export const handleGetStringStartDateToEndDate = (startDate, endDate) => {
  let startDateMonthAndYear =
    startDate && moment(startDate).format("MMMM YYYY");
  let endDateMonthAndYear = endDate && moment(endDate).format("MMMM YYYY");
  let finalStartDate = "";
  if (startDateMonthAndYear === endDateMonthAndYear) {
    // means startDate's still in the same month and year with endDate
    finalStartDate = startDate && moment(startDate).format("DD");
  } else {
    // means startDate's not the same month and year with endDate
    finalStartDate = startDate && moment(startDate).format("DD MMMM YYYY");
  }
  let finalEndDate = endDate && moment(endDate).format("DD MMMM YYYY");
  return `${finalStartDate} - ${finalEndDate}`;
};
