import Axios from "../../src/helpers/axios";

export const getAllProductsInCart = (query) => {
  let url = query
    ? `/api/ecommerce/v1/carts?${query}`
    : "/api/ecommerce/v1/carts";
  return Axios.get(url);
};

export const addProductToCart = (body) => {
  return Axios.post("/api/ecommerce/v1/carts/add", body);
};

export const deleteProductFromCart = (ids) => {
  return Axios.delete(`/api/ecommerce/v1/carts/delete/${ids}`);
};

export const calculateCartOverview = (query, body) => {
  let url = query
    ? `/api/ecommerce/v1/carts/multiple/calculate?${query}`
    : "/api/ecommerce/v1/carts/multiple/calculate";
  return Axios.post(url, body);
};

export const getShippingOptions = (query, body) => {
  let url = query
    ? `/api/ecommerce/v1/carts/shipping/options?${query}`
    : "/api/ecommerce/v1/carts/shipping/options";
  return Axios.post(url, body);
};

export const addCartQty = (body) => {
  return Axios.post(`/api/ecommerce/v1/carts/add/qty/${body.cart_id}`, body);
};

export const reduceCartQty = (body) => {
  return Axios.post(`/api/ecommerce/v1/carts/reduce/qty/${body.cart_id}`, body);
};
