import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Styles
import "../../styles/authentication.css";

// Icons and Images
import PasswordLogin from "../../assets/authenticationIconsAndImages/PasswordLogin.png";
import EyePasswordHide from "../../assets/authenticationIconsAndImages/EyePasswordHide.png";
import EyePasswordShow from "../../assets/authenticationIconsAndImages/EyePasswordShow.png";

// Components
import { InputText } from "../../components/Inputs";

// Apis
import { resetPasswordUser } from "../../api/authentications";

// Helpers
import { timer } from "../../helpers/timer";

const ResetPassword = (props) => {
  const {
    setterOpenFullscreen,
    authenticationState,
    CloseFullscreenBtn,
    isMobileSize,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paramsTokenResetPassword = useSelector(
    (state) => state.paramsTokenResetPassword
  );

  const [passwordRegister, setRegisterPassword] = useState("");
  const [reTypePasswordRegister, setReTypePasswordRegister] = useState("");
  const [passwordInputType, setPasswordInputType] = useState("password");
  const { userResetPasswordToken } = useParams();
  useEffect(() => {
    if (userResetPasswordToken) {
      dispatch({
        type: "SET_SHOW_POP_UP_BANNER",
        showPopUpBanner: false,
      });
      dispatch({
        type: "SET_TOKEN_RESET_PASSWORD",
        paramsTokenResetPassword: userResetPasswordToken,
      });
      navigate("/");
    }
  }, [userResetPasswordToken]);

  const handleOnClickIconPassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const handleClickCloseModal = () => {
    dispatch({
      type: "SET_TOKEN_RESET_PASSWORD",
      paramsTokenResetPassword: "",
    });
    setterOpenFullscreen({
      ...authenticationState,
      resetPassword: false,
      tokenResetPassword: "",
    });
  };

  const handleClickSubmitBtn = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await resetPasswordUser({
      token: paramsTokenResetPassword,
      password: passwordRegister,
    });
    if (result && result.data) {
      const { data: { success } = {} } = result || {};
      if (success) {
        handleClickCloseModal();
        const beforeFunction = () => {
          return dispatch({
            type: "SET_IS_SUCCESS_RESET_PASSWORD",
            isSuccessResetPassword: true,
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_IS_SUCCESS_RESET_PASSWORD",
            isSuccessResetPassword: false,
          });
        };
        timer(beforeFunction, afterFunction, 3000);
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  return (
    <div className="loginBoxMainContainer">
      <div
        className="closeBtn"
        style={{ width: "100%", marginRight: isMobileSize ? "2em" : "4em" }}
        onClick={() => handleClickCloseModal()}
      >
        <img
          src={CloseFullscreenBtn}
          alt="close-fullscreen-btn"
          width={isMobileSize ? 18 : 28}
          height={isMobileSize ? 18 : 28}
        />
      </div>
      <div className="tabNameStyle">Reset Password</div>
      <div className="signInToYourAccountStyle"></div>
      <div className="loginInputContainer">
        <div>
          <div className="inputLabelWithIconPassword">
            <img src={PasswordLogin} alt="password-icon" />
            <div className="inputLabel">Password</div>
          </div>
          <div className="inputPasswordContainer">
            <InputText
              type={passwordInputType}
              width={"100%"}
              fontSize={isMobileSize ? "14px" : "16px"}
              border={"1px solid #000000"}
              borderRadius={"10px"}
              height={"5vh"}
              placeholder={"Fill in password"}
              name={"registerPassword"}
              value={passwordRegister}
              setter={setRegisterPassword}
            />
            <div
              className="loginEyePassword"
              onClick={() => handleOnClickIconPassword()}
            >
              <img
                src={
                  passwordInputType === "password"
                    ? EyePasswordHide
                    : EyePasswordShow
                }
                alt="show-hide-password-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="loginInputContainer">
        <div>
          <div className="inputLabelWithIconPassword">
            <img src={PasswordLogin} alt="password-icon" />
            <div className="inputLabel">Retype Password</div>
          </div>
          <div className="inputPasswordContainer">
            <InputText
              type={passwordInputType}
              width={"100%"}
              fontSize={isMobileSize ? "14px" : "16px"}
              border={"1px solid #000000"}
              borderRadius={"10px"}
              height={"5vh"}
              placeholder={"Re-type password"}
              name={"registerPassword"}
              value={reTypePasswordRegister}
              setter={setReTypePasswordRegister}
            />
            <div
              className="loginEyePassword"
              onClick={() => handleOnClickIconPassword()}
            >
              <img
                src={
                  passwordInputType === "password"
                    ? EyePasswordHide
                    : EyePasswordShow
                }
                alt="show-hide-password-icon"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="btnSignInContainer"
        style={{
          background: !passwordRegister
            ? // || !isEmail || !isPassword
              "#C4C4C4"
            : "#0d4c86",
          marginTop: isMobileSize ? "1.5em" : "2em",
        }}
        onClick={() => handleClickSubmitBtn()}
      >
        <div>Submit</div>
      </div>
    </div>
  );
};

export default ResetPassword;
