import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import CIcon from "@coreui/icons-react";

import SearchIcon from "../assets/navbarIcons/SearchIcon.png"

export const InputText = (props) => {
  const {
    value,
    placeholder,
    name,
    border,
    borderRadius,
    fontWeight,
    fontSize,
    setter,
    paddingLeft,
    functionSetter,
    phoneNumberState,
    setterPhoneNumber,
    disabled,
    type,
    onKeyDown,
    textAlign,
  } = props;

  return (
    <input
      type={type ? type : "text"}
      id="myInputText"
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={({ target: { value } }) =>
        functionSetter
          ? functionSetter(value, phoneNumberState, setterPhoneNumber)
          : setter
            ? setter(value)
            : null
      }
      disabled={disabled}
      onKeyDown={onKeyDown ? onKeyDown : null}
      style={{
        paddingLeft: paddingLeft ? paddingLeft : "1em",
        border: border ? border : "1px solid #000000",
        borderRadius: borderRadius ? borderRadius : "",
        fontStyle: "normal",
        fontWeight: fontWeight ? fontWeight : 400,
        fontSize: fontSize ? fontSize : "16px",
        textAlign: textAlign ? textAlign : "",
        ...props,
      }}
    />
  );
};

export const InputTextArea = (props) => {
  const {
    placeholder,
    name,
    border,
    fontWeight,
    fontSize,
    setter,
    value,
    isMobileSize,
    disabled,
  } = props;
  return (
    <textarea
      type="text"
      id="myInputText"
      name={name}
      placeholder={placeholder}
      onChange={({ target: { value } }) => setter(value)}
      value={value}
      disabled={disabled}
      style={{
        paddingTop: "1vh",
        paddingLeft: isMobileSize ? "3vw" : "0.75vw",
        border: border ? border : "1px solid #000000",
        fontStyle: "normal",
        fontWeight: fontWeight ? fontWeight : 400,
        fontSize: fontSize ? fontSize : "16px",
        ...props,
      }}
    />
  );
};

export const InputDropdown = (props) => {
  const {
    options,
    styles,
    phoneNumberState,
    setterPhoneNumber,
    functionSetter,
    value,
    placeholder,
    disabled,
    optionStyles,
    selectedValue,
  } = props;
  return (
    <Select
      value={value}
      selectedValue={selectedValue}
      placeholder={<div>{placeholder}</div>}
      onChange={(value) =>
        functionSetter(value, phoneNumberState, setterPhoneNumber)
      }
      options={options}
      isDisabled={disabled}
      styles={{
        menu: (provided, state) => ({
          ...provided,
          width: styles.menuWidth,
        }),
        control: (provided, state) => ({
          ...provided,
          ...styles,
        }),
        option: () =>
        // styles, { data, isDisabled, isFocused, isSelected }
        ({
          ...optionStyles,
          padding: "0.5em 1em 0 1em",
          cursor: "pointer",
        }),
      }}
    />
  );
};

export const InputDropdownAsync = (props) => {
  const [inputValue, setInputValue] = useState("");
  const {
    options,
    styles,
    phoneNumberState,
    setterPhoneNumber,
    functionSetter,
    value,
    placeholder,
    disabled,
    optionStyles,
    selectedValue,
  } = props;

  const loadOptions = (inputValue, callback) => {
    let finalOptions = options;
    if (inputValue) {
      finalOptions = finalOptions.filter((opt) => {
        let countryName = opt.value.name.toLowerCase();
        let lowerCaseInputValue = inputValue.toLowerCase();
        if (countryName.includes(lowerCaseInputValue)) {
          return opt;
        }
      });
    }
    callback(finalOptions);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  return (
    <AsyncSelect
      value={value}
      selectedValue={selectedValue}
      placeholder={<div>{placeholder}</div>}
      onChange={(value) =>
        functionSetter(value, phoneNumberState, setterPhoneNumber)
      }
      loadOptions={loadOptions}
      isDisabled={disabled}
      isSearchable
      cacheOptions
      defaultOptions
      inputValue={inputValue}
      onInputChange={handleInputChange}
      styles={{
        menu: (provided, state) => ({
          ...provided,
          width: styles.menuWidth,
        }),
        control: (provided, state) => ({
          ...provided,
          ...styles,
        }),
        option: () =>
        // styles, { data, isDisabled, isFocused, isSelected }
        ({
          ...optionStyles,
          padding: "0.5em 1em 0 1em",
          cursor: "pointer",
        }),
      }}
    />
  );
};

export const PhoneNumberInput = ({
  selectedCountry,
  onChangeCountry,
  phoneNumberValue,
  onChangePhoneNumber,
  countryOptions,
  isMobileSize,
  disabled,
}) => {
  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null)

  const [searchQuery, setSearchQuery] = useState("")
  const filteredCountryOptions = searchQuery ? countryOptions?.filter(option => option.value?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase())) : countryOptions
  const [dropdown, setDropdown] = useState({
    isOpen: false,
  })

  const onClickCountryItem = (value) => {
    onChangeCountry(value)
    setSearchQuery("")
    setDropdown(prev => ({ ...prev, isOpen: false }))
  }

  const handleOutsideClick = (event) => {
    if (dropdownRef && !dropdownRef?.current?.contains(event.target) && dropdownButtonRef && !dropdownButtonRef?.current?.contains(event.target)) {
      setDropdown(prev => ({ ...prev, isOpen: false }))
      setSearchQuery("")
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <div className="flex border border-black rounded-md relative text-sm sm:text-base">
      <div
        ref={dropdownButtonRef}
        className={`${disabled ? "bg-transparent sm:bg-neutral-50" : "cursor-pointer"} flex justify-center items-center px-3 sm:px-4 py-2 border-r select-none rounded-l-md whitespace-nowrap`}
        onClick={() => disabled ? undefined : setDropdown(prev => ({ ...prev, isOpen: !prev.isOpen }))}
      >
        {selectedCountry?.icon && (
          <CIcon className="mr-1" icon={selectedCountry?.icon} size="sm" height={isMobileSize ? 16 : 24} width={isMobileSize ? 16 : 24} />
        )}
        {selectedCountry?.phone_code}
      </div>
      <input
        className="w-[100%] px-3 sm:px-4 py-2 rounded-r-md overflow-clip"
        placeholder="Fill in phone number"
        disabled={disabled}
        value={phoneNumberValue}
        onChange={(e) => onChangePhoneNumber(e?.target?.value)}
      />
      {dropdown?.isOpen && (
        <div ref={dropdownRef} className="z-50 absolute top-0 left-0 mt-12 bg-white w-full border shadow-lg rounded-md flex flex-col max-h-52">
          <div className="flex items-center">
            <label className="px-3 sm:px-4 py-2 cursor-pointer" htmlFor="phone_country_search">
              <img className="w-3 h-3 sm:w-5 sm:h-5" src={SearchIcon} alt="search" />
            </label>
            <input autoFocus id="phone_country_search" className="flex-1 px-2 py-2" placeholder="Search for countries" onChange={(e) => setSearchQuery(e.target.value)} />
          </div>
          <div className="flex-1 overflow-y-auto py-1">
            {filteredCountryOptions?.map((option, i) => (
              <div
                key={i}
                className="px-3 sm:px-4 py-1 hover:bg-neutral-200 cursor-pointer transition-all flex items-center gap-2"
                onClick={() => onClickCountryItem(option)}
              >
                {option?.icon && (
                  <span>{option?.icon}</span>
                )}
                <span className="flex-1">{option?.value?.name}</span>
                {option.value.phone_code && (
                  <span className="text-[10px] sm:text-xs border rounded-xl px-2 py-1 whitespace-nowrap">
                    {option.value.phone_code}
                  </span>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
