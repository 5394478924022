import React from "react";

// Styles
import "../styles/notification.css";

// Icons
import SuccessNotificationIcon from "../assets/notificationIcons/SuccessNotificationIcon.png";

const Notification = ({ msg, success }) => {
  return (
    <div className="notificationMainContainer">
      <div className="notificationContentContainer">
        <div className="notificationContainer">
          {success ? (
            <img src={SuccessNotificationIcon} alt="notification-icon" />
          ) : null}
          <div>{msg}</div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
