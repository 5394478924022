import Axios from "../../src/helpers/axios";

export const getWishlist = () => {
  return Axios.get("/api/ecommerce/v1/wishlist");
};

export const createNewWishlist = (body) => {
  return Axios.post("/api/ecommerce/v1/wishlist/create", body);
};

export const deleteWishlist = (wishlistId) => {
  return Axios.delete(`/api/ecommerce/v1/wishlist/delete/${wishlistId}`);
};
