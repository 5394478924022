import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Styles
import "../../styles/myProfile.css";

// Components
import MyProfileTab from "./Tabs/MyProfileTab";
import WishlistTab from "./Tabs/WishlistTab";
import MyOrdersTab from "./Tabs/MyOrdersTab";
import MyAddressTab from "./Tabs/MyAddressTab";
import MyPromoCodeTab from "./Tabs/MyPromoCodeTab";
import LoginGuard from "../../components/LoginGuard";

// Icons and Images
import ArrowDownIcon from "../../assets/navbarIcons/ArrowDownIcon.png";
import ArrowUpIcon from "../../assets/navbarIcons/ArrowUpIcon.png";

// Apis
import { logoutUser } from "../../api/authentications";

// Cookies
import Cookies from "js-cookie";

// Helpers
import { timer } from "../../helpers/timer";

const MyProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const userDetail = useSelector((state) => state.userDetail);
  const isMobileSize = useSelector((state) => state.isMobileSize);
  const searchState = useSelector((state) => state.searchState);
  const wishlist = useSelector((state) => state.wishlist);

  const [tabs, setTabs] = useState([
    {
      tabName: "My profile",
      isActiveTab: false,
      path: "/my-profile",
      isOpenMenu: false,
    },
    {
      tabName: "Wishlist",
      isActiveTab: false,
      path: "/wishlist",
      isOpenMenu: false,
    },
    {
      tabName: "My orders",
      isActiveTab: false,
      path: "/my-orders",
      isOpenMenu: false,
    },
    {
      tabName: "My address",
      isActiveTab: false,
      path: "/my-address",
      isOpenMenu: false,
    },
    {
      tabName: "Promo code",
      isActiveTab: false,
      path: "/my-promo-code",
      isOpenMenu: false,
    },
  ]);

  const [prevTabActive, setPrevTabActive] = useState(null)

  useEffect(() => {
    if (pathname) {
      let finalTabs = tabs;
      finalTabs = finalTabs.map((tab) => {
        const { tabName } = tab || {};
        if (pathname.includes("/my-profile") && tabName === "My profile") {
          setPrevTabActive(tabName)
          tab = { ...tab, isActiveTab: true };
        } else if (pathname.includes("/wishlist") && tabName === "Wishlist") {
          setPrevTabActive(tabName)
          tab = { ...tab, isActiveTab: true };
        } else if (pathname.includes("/my-orders") && tabName === "My orders") {
          setPrevTabActive(tabName)
          tab = { ...tab, isActiveTab: true };
        } else if (
          pathname.includes("/my-address") &&
          tabName === "My address"
        ) {
          setPrevTabActive(tabName)
          tab = { ...tab, isActiveTab: true };
        } else if (
          pathname.includes("/my-promo-code") &&
          tabName === "Promo code"
        ) {
          setPrevTabActive(tabName)
          tab = { ...tab, isActiveTab: true };
        } else {
          tab = { ...tab, isActiveTab: false };
        }
        return tab;
      });
      setTabs(finalTabs);
    }
  }, [pathname]);

  const handleOnClickTab = (path, tabName) => {
    let finalTabs = tabs;
    finalTabs = finalTabs.map((el) => {
      if (tabName === el.tabName) {
        if (el.isActiveTab == true) {
          setPrevTabActive(tabName)
        } else {
          el.isActiveTab = true;
        }
      } else {
        el.isActiveTab = false;
      }
      return el;
    });
    setTabs(finalTabs);

    if (path) {
      if (prevTabActive == tabName) {
        window.location.reload()
      } else {
        navigate(path);
      }
    } else {
      handleOnClickSignOut();
    }
  };

  const handleOnClickSignOut = async () => {
    try {
      const result = await logoutUser();
      if (
        result &&
        result.data &&
        result.data.message &&
        result.data.message === "User logged out"
      ) {
        Cookies.remove("ut");
        dispatch({ type: "SET_USER_DETAIL", userDetail: null });
        dispatch({ type: "SET_USERS_CART", shoppingCarts: [] });
        dispatch({ type: "SET_TOTAL_USERS_CART", totalUsersCart: null });
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: true,
              msg: "Successfully logged out",
              show: true,
            },
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
        navigate("/");
      }
    } catch (err) {
      console.log(err.response, "err <<<");
    }
  };

  const handleOnClickMenu = (tab, isMobile) => {
    let finalTabs = tabs;
    finalTabs = finalTabs.map((eachTab, index) => {
      const { tabName } = eachTab || {};
      if (isMobile) {
        if (tabName === tab.tabName) {
          eachTab.isOpenMenu = !eachTab.isOpenMenu;
          eachTab.isActiveTab = !eachTab.isActiveTab;
        }
      } else {
        if (tabName === tab.tabName) {
          eachTab.isOpenMenu = true;
          eachTab.isActiveTab = true;
        } else {
          eachTab.isOpenMenu = false;
          eachTab.isActiveTab = false;
        }
      }
      return eachTab;
    });
    setTabs(finalTabs);
  };

  return (
    <LoginGuard>
      <div className="myProfileMainContainer">
        {/* {false ? (
          <div className="myProfileContainer">
            <div className="accordionWrapper">
              <div className="accordionListContainer">
                {tabs.map((tab, index) => {
                  const { isOpenMenu, tabName } = tab || {};
                  return (
                    <div className="eachAccordionContainer" key={index}>
                      <div
                        className="eachAccordionHeader"
                        onClick={() => handleOnClickMenu(tab, true)}
                      >
                        <div>{tabName}</div>
                        {tabName && tabName !== "Sign out" ? (
                          <div
                            style={
                              isOpenMenu
                                ? {
                                  marginTop: "0.1em",
                                }
                                : {
                                  display: "flex",
                                  alignItems: "center",
                                  marginTop: "0.1em",
                                }
                            }
                          >
                            <img
                              src={isOpenMenu ? ArrowDownIcon : ArrowUpIcon}
                              width={15}
                              height={8}
                              alt="checkbox-icon"
                            />
                          </div>
                        ) : null}
                      </div>
                      {isOpenMenu ? (
                        <div className="eachAccordionContent">
                          {tabName === "My profile" ? (
                            <MyProfileTab
                              dispatch={dispatch}
                              userDetail={userDetail}
                              isMobileSize={isMobileSize}
                            />
                          ) : tabName === "Wishlist" ? (
                            <WishlistTab
                              dispatch={dispatch}
                              isMobileSize={isMobileSize}
                              searchState={searchState}
                              wishlist={wishlist}
                            />
                          ) : tabName === "My orders" ? (
                            <MyOrdersTab
                              navigate={navigate}
                              isMobileSize={isMobileSize}
                              dispatch={dispatch}
                              searchState={searchState}
                            />
                          ) : tabName === "My address" ? (
                            <MyAddressTab
                              dispatch={dispatch}
                              userDetail={userDetail}
                              isMobileSize={isMobileSize}
                            />
                          ) : tabName === "Promo code" ? (
                            <MyPromoCodeTab
                              isMobileSize={isMobileSize}
                              dispatch={dispatch}
                            />
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : ( */}
        <div className="myProfileContainer">
          <div className="myProfileSideMenuContainer">
            {tabs &&
              Array.isArray(tabs) &&
              tabs.length > 0 &&
              tabs.map((tab, index) => {
                const { tabName, isActiveTab, path } = tab || {};
                return (
                  <div
                    key={index}
                    className="eachSideMenuTabContainer"
                    onClick={() => handleOnClickTab(path, tabName)}
                    style={{
                      borderBottom: (isActiveTab && isMobileSize) ? "1px solid grey" : ""
                    }}
                  >
                    <div style={{
                      fontWeight: isActiveTab ? "700" : "400",
                    }}>
                      {tabName}
                    </div>
                  </div>
                );
              })}
          </div>
          {tabs && Array.isArray(tabs) && tabs.length > 0 &&
            tabs.map((tab, index) => {
              const { tabName, isActiveTab } = tab || {};
              if (isActiveTab) {
                if (tabName === "My profile") {
                  return (
                    <MyProfileTab
                      isMobileSize={isMobileSize}
                      key={index}
                      userDetail={userDetail}
                      dispatch={dispatch}
                    />
                  );
                } else if (tabName === "Wishlist") {
                  return (
                    <WishlistTab
                      key={index}
                      isMobileSize={isMobileSize}
                      searchState={searchState}
                      dispatch={dispatch}
                      wishlist={wishlist}
                    />
                  );
                } else if (tabName === "My orders") {
                  return (
                    <MyOrdersTab
                      key={index}
                      navigate={navigate}
                      dispatch={dispatch}
                      searchState={searchState}
                      isMobileSize={isMobileSize}
                    />
                  );
                } else if (tabName === "My address") {
                  return (
                    <MyAddressTab
                      key={index}
                      userDetail={userDetail}
                      isMobileSize={isMobileSize}
                      dispatch={dispatch}
                    />
                  );
                } else if (tabName === "Promo code") {
                  return <MyPromoCodeTab
                    key={index}
                    dispatch={dispatch}
                    isMobileSize={isMobileSize}
                  />
                } else {
                  return null;
                }
              } else {
                return null;
              }
            })}
        </div>
        {/* )} */}
      </div>
    </LoginGuard>
  );
};

export default MyProfile;
