import React from "react";

import '../../styles/toggleButton.css'

function ToggleButton({ name, checked, onChange }) {
  return (
    <label className="switch">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <span className="slider"></span>
    </label>
  );
}

export default ToggleButton;
