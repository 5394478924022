import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import ToggleButton from "./ToggleButton";

const setCookie = (name, value, options) => {
  let secure = true;
  if (process.env.NODE_ENV === "development") secure = false;
  Cookies.set(name, value, { path: "/", secure, ...options });
};

const getCookie = (name) => {
  return Cookies.get(name);
};

function CookieConsent({ handleAcceptCookieConsent }) {
  const [showSettings, setShowSettings] = useState(false);
  const [cookiesPreferences, setCookiesPreferences] = useState([
    {
      id: 1,
      name: "performance",
      enabled: true,
      title: "Performance cookies",
      description: `
                Performance cookies help us improve the functionality of our website by collecting information
                and reporting on your use of the website as well as improving your user experience.
                All information these cookies collect is aggregated and therefore anonymous.
            `,
    },
    {
      id: 2,
      name: "functional",
      enabled: true,
      title: "Functional cookies",
      description: `
                Functional cookies enable the website to provide enhanced functionality.
                They may be set by us or by third party providers whose services we have added to our pages.
                If you do not allow these cookies then some or all of these services may not function properly.
            `,
    },
    {
      id: 3,
      name: "targeting",
      enabled: true,
      title: "Targeting cookies",
      description: `
                Targeting cookies are set through our site by third party providers.
                They may be used by those companies to build a profile of your interests
                and show you relevant adverts on other sites.
                They do not store directly personal information,
                but are based on uniquely identifying your browser and internet device.
                If you do not allow these cookies, you will experience less targeted advertising.
            `,
    },
  ]);

  const isMobileSize = useSelector((state) => state.isMobileSize);

  const handleOnChangeType = (name) => {
    setCookiesPreferences((prev) =>
      prev.map((item) => {
        let temp = Object.assign({}, item);
        if (temp.name === name) {
          temp.enabled = !item.enabled;
        }
        return temp;
      })
    );
  };

  const handleCancel = () => {
    if (showSettings) {
      setShowSettings(false);
    } else {
      setCookiesPreferences((prev) =>
        prev.map((item) => {
          const value = getCookie(`cookie_${item.id}`);
          let temp = Object.assign({}, item);
          if (value) {
            temp.enabled = value === "true" ? true : false;
          }
          return temp;
        })
      );
      setShowSettings(true);
    }
  };

  const handleSave = () => {
    cookiesPreferences.forEach((pref) => {
      setCookie(`cookie_${pref.id}`, pref.enabled ? "true" : "false");
    });
    setCookie("cookie_consent", true);
    handleAcceptCookieConsent();
  };

  useEffect(() => {
    setCookiesPreferences((prev) =>
      prev.map((item) => ({ ...item, enabled: true }))
    );
  }, []);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => document.body.classList.remove("modal-open");
  }, []);

  return (
    <div className={`fixed top-0 bottom-0 right-0 left-0 z-50 flex justify-center ${isMobileSize ? "items-end" : "items-center"}`}>
      <div
        className={`flex flex-col fixed bg-white z-50 sm:mb-4 md:mb-8 sm:mx-4 ${
          showSettings ? "max-w-6xl" : "max-w-6xl"
        } max-h-[90vh] text-left py-4 sm:px-12 sm:py-6 space-y-4 sm:space-y-6`}
      >
        <p className="font-bold text-lg md:text-xl px-4 sm:px-0">
          {showSettings ? "Manage Consent Preferences" : "We Use Cookies"}
        </p>
        {showSettings ? (
          <div className="flex-1 overflow-y-auto px-4 sm:px-0">
            <div className="mb-4">
              <div className="flex justify-between">
                <p className="text-sm sm:text-base font-bold mb-2">
                  Essential cookies
                </p>
                <p className="text-xs sm:text-sm text-maplexxon-blue font-light">
                  Always Active
                </p>
              </div>
              <p className="text-xs sm:text-sm">
                Essential cookies are required for the operation of our website
                and cannot be switched off. You can block or disable these using
                your internet browser but this may affect website functionality
              </p>
            </div>
            {cookiesPreferences.map((pref, i) => (
              <div className="mb-4">
                <div className="flex justify-between">
                  <p className="text-sm sm:text-base font-bold mb-2">
                    {pref.title}
                  </p>
                  <ToggleButton
                    name={pref.name}
                    checked={pref.enabled}
                    onChange={() => handleOnChangeType(pref.name)}
                  />
                </div>
                <p className="text-xs sm:text-sm">{pref.description}</p>
              </div>
            ))}
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto px-4 sm:px-0">
            <p className="text-xs md:text-sm">
              We use essential cookies to make our website work. others help us
              deliver the best experience on our website. By selecting "
              <b>Accept All</b>", you consent to our use of cookies, or select "
              <b>Manage Cookies</b>" to choose type of cookies you will accept.
            </p>
            {/* <p className="text-xs md:text-sm">
              For more detailed information about the cookies we use, see
              our&nbsp;
              <a target="_blank" href="privacy-policy">
                <b>Privacy Policy</b>
              </a>
              .
            </p> */}
          </div>
        )}

        <div className="flex gap-4 max-w-xl mx-auto w-full px-4 sm:px-0 ">
          <button
            className="flex-1 text-xs md:text-sm border border-maplexxon-blue p-2 text-maplexxon-blue"
            onClick={handleCancel}
          >
            {showSettings ? "Cancel" : "Manage Cookies"}
          </button>
          <button
            className="flex-1 text-xs md:text-sm bg-maplexxon-blue text-white p-2"
            onClick={handleSave}
          >
            {showSettings ? "Save" : "Accept All"}
          </button>
        </div>
      </div>
      <div className="fixed top-0 bottom-0 right-0 left-0 z-40 bg-maplexxon-transparant-black" />
    </div>
  );
}

export default CookieConsent;
