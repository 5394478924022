import React, { useEffect, useState } from "react";

const HideOnScroll = ({ children }) => {

  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  const onScroll = () => {
    const scrollPos = window.scrollY
    if (scrollPos == 0) {
      setIsHidden(false)
    } else {
      setIsHidden(true)
    }
  }

  return (
    !isHidden && children
  )

}

export default HideOnScroll