import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// React Slideshow Image
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

// styles
import "../styles/landingPage.css";

// Images
import LandingPageImage1 from "../assets/landingPageImagesAndIcons/LandingPageImage1.png";
import LandingPageRow3Image1 from "../assets/landingPageImagesAndIcons/LandingPageRow3Image1.png";
import LandingPageImage2 from "../assets/landingPageImagesAndIcons/LandingPageImage2.png";
import LandingPageImage3 from "../assets/landingPageImagesAndIcons/LandingPageImage3.jpg";
import LandingPageImage4 from "../assets/landingPageImagesAndIcons/LandingPageImage4.jpg"
import LandingPageRow2Image1 from "../assets/landingPageImagesAndIcons/LandingPageRow2Image1.png";
import LandingPageRow2Image2 from "../assets/landingPageImagesAndIcons/LandingPageRow2Image2.png";
import ArrowRightIcon from "../assets/landingPageImagesAndIcons/ArrowRightIcon.png";

// Components
import FullscreenView from "../components/FullscreenView";

// APIs
import { handleFetchAllProducts } from "../helpers/handleFetchAllProducts";
import Scroller from "../components/Scroller";
import { handleAttachQuery } from "../helpers/attach-queries";

const LandingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showPopUpBanner = useSelector((state) => state.showPopUpBanner);
  const isMobileSize = useSelector((state) => state.isMobileSize);
  const searchState = useSelector((state) => state.searchState);
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);
  const productListPageState = useSelector(
    (state) => state.productListPageState
  );

  const [randomCategoryList, setRandomCategoryList] = useState([]);

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    handleGet3RandomCategories();
  }, [categories]);

  const handleCheckAvailabilty = (array, value) => {
    let alreadyExist = array.some((obj) => {
      return value === obj.name;
    });
    return alreadyExist;
  };

  const handleGet3RandomCategories = () => {
    if (categories && Array.isArray(categories) && categories.length > 0) {
      const finalRandomCategoryList = [];
      let counter = 0;
      for (let l = 0; l < categories.length; l++) {
        if ((isMobileSize && counter < 4) || (!isMobileSize && counter < 3)) {
          let randomCategory =
            categories[Math.floor(Math.random() * categories.length)];
          let alreadyExist = handleCheckAvailabilty(
            finalRandomCategoryList,
            randomCategory.name
          );
          if (!alreadyExist) {
            finalRandomCategoryList.push(randomCategory);
            counter += 1;
          }
        } else {
          break;
        }
      }
      setRandomCategoryList(finalRandomCategoryList);
    }
  };

  const fetchProducts = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const queries = handleAttachQuery(searchState, productListPageState)
    const result = await handleFetchAllProducts(queries);
    dispatch({
      type: "SET_PRODUCTS",
      products: result,
    });
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  const properties = {
    prevArrow: (
      <button
        className="arrow arrow-left"
        style={{
          position: "absolute",
          zIndex: "1",
          cursor: "pointer",
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          color: "#FFFF",
          fontSize: isMobileSize ? "35px" : "80px",
          padding: isMobileSize ? "0px" : "20px",
          borderRadius: "50%",
          left: 10,
        }}
      >
        <span>&lt;</span>
      </button>
    ),
    nextArrow: (
      <button
        className="arrow arrow-right"
        style={{
          position: "absolute",
          zIndex: "1",
          cursor: "pointer",
          border: "none",
          outline: "none",
          backgroundColor: "transparent",
          color: "#FFFF",
          fontSize: isMobileSize ? "35px" : "80px",
          padding: isMobileSize ? "0px" : "20px",
          borderRadius: "50%",
          right: 10,
        }}
      >
        <span>&gt;</span>
      </button>
    ),
    indicators: true
  };
  
  return (
    <div className="landingPageContainer">
      <div
        className="landingPageImage1"
        data-aos="fade-up"
        data-aos-duration="3000"
      >
        <Slide {...properties}>
          <div>
            <img src={LandingPageImage1} alt="Landing-Page-1"></img>
          </div>
          <div>
            <img src={LandingPageImage3} alt="Landing-Page-3"></img>
          </div>
          <div>
            <img src={LandingPageImage4} alt="Landing-Page-4"></img>
          </div>
        </Slide>
      </div>
      {randomCategoryList &&
        Array.isArray(randomCategoryList) &&
        randomCategoryList.length > 0 ? (
        <div className="landingPageBodyContent">
          <div className="landingPage3RowImages grid grid-cols-2 sm:grid-cols-3 gap-4">
            {randomCategoryList.map((randomCategory) => {
              const { id, image_url, name } = randomCategory;
              return (
                <div
                  key={id}
                  className="landingPage3RowImagesEachImage"
                  data-aos="fade-up"
                  data-aos-duration="3000"
                >
                  <img
                    src={image_url ? image_url : LandingPageRow3Image1}
                    alt="Landing-Page-Row-3-1"
                  ></img>
                  <div className="landingPage3RowImagesEachImageLabel">
                    {name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      <div className="landingPageImage2">
        <div className="slide-container">
          <div
            className="image-cover"
            data-aos="fade-up"
            data-aos-duration="3000"
          >
            <img src={LandingPageImage2} alt="Landing-Page-2" />
            <div className="content">
              <div className="contentLabel1">Find the best Club for you</div>
              <div className="contentLabel2">
                Lorem ipsum dolor sit amet consectetur. Maecenas lectus ac eget
                nam ultrices consectetur mauris eget accumsan. Ultricies
                facilisis non quis mauris ac lacus amet eu tempor. Feugiat
                pellentesque leo dolor vestibulum nec duis.
              </div>
              <div className="contentButtonExplore">Explore now</div>
            </div>
          </div>
        </div>
      </div>
      <div className="landingPageBodyContent">
        <div className="landingPage2RowImages">
          <div className="slide-container-row-2-images">
            <div
              className="image-cover"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img src={LandingPageRow2Image1} alt="Landing-Page-Row-2-1" />
              <div className="content-row-2-images">Consignment with us</div>
            </div>
          </div>
          <div className="slide-container-row-2-images">
            <div
              className="image-cover"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img src={LandingPageRow2Image2} alt="Landing-Page-Row-2-2" />
              <div className="content-row-2-images">Golf Events and Tours</div>
            </div>
          </div>
        </div>
        <div className="landingPageFeaturedProductsContainer">
          <div className="landingPageFeaturedProductTitle">
            Featured products
          </div>
          {!isMobileSize ? (
            <div
              className="landingPageFeaturedProductViewAllBtn"
              onClick={() =>
                navigate(
                  `/items?${handleAttachQuery(
                    searchState,
                    productListPageState
                  )}`
                )
              }
            >
              <div className="landingPageFeaturedProductViewAllBtnLabel">
                View all
              </div>
              <div>
                <img
                  src={ArrowRightIcon}
                  alt="arrow-right-icon"
                  height={15}
                  width={9.5}
                ></img>
              </div>
            </div>
          ) : null}
          <div className="landingPageFeaturedProducts">
            <Scroller
              contents={products}
              imageContent={true}
              flagger={"landing-page-items"}
              isMobileSize={isMobileSize}
              searchState={searchState}
              navigate={navigate}
            />
          </div>
          {isMobileSize ? (
            <button className="landingPageFeaturedProductViewAllBtn mx-auto"
              onClick={() =>
                navigate(
                  `/items?${handleAttachQuery(
                    searchState,
                    productListPageState
                  )}`
                )
              }
            >
              <div className="landingPageFeaturedProductViewAllBtnLabel">
                View all
              </div>
              <img className="h-1" src={ArrowRightIcon} alt="arrow-right-icon"></img>
            </button>
          ) : null}
        </div>
      </div>
      {showPopUpBanner ? (
        <FullscreenView
          setterOpenFullscreen={() =>
            dispatch({
              type: "SET_SHOW_POP_UP_BANNER",
              showPopUpBanner: false,
            })
          }
          flagger={"landing page"}
        />
      ) : null}
    </div>
  );
};

export default LandingPage;
