import Axios from "../../src/helpers/axios";

export const getDetailProduct = (productId, query) => {
  return Axios.get(`/api/ecommerce/v1/products/id/${productId}?${query}`);
};

export const getAllProducts = (query) => {
  let url = query
    ? `/api/ecommerce/v1/products?${query}`
    : "/api/ecommerce/v1/products";
  return Axios.get(url);
};

export const getAllProductCount = (query) => {
  let url = query
    ? `/api/ecommerce/v1/products/total?${query}`
    : "/api/ecommerce/v1/products/total";
  return Axios.get(url);
};

export const searchAll = (body) => {
  return Axios.post(`/api/ecommerce/v1/products/search`, body);
};

export const getCombinationPriceAndStock = (body) => {
  return Axios.post(
    `/api/ecommerce/v1/products/combination/price/stock/${body.product_id}`,
    body
  );
};
