import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Helpers
import { timer } from "../helpers/timer";

// Components
import FullscreenView from "./FullscreenView";

const CheckInternetConnection = () => {
  const dispatch = useDispatch();
  const [isOnline, setIsOnline] = useState(() => {
    if (navigator.onLine) {
      return true;
    } else {
      return false;
    }
  });

  useEffect(() => {
    window.ononline = (e) => {
      setIsOnline(true);
      const beforeFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: true,
            msg: "Connected to network.",
            show: true,
          },
        });
      };
      const afterFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: false,
            msg: "",
            show: false,
          },
        });
      };
      timer(beforeFunction, afterFunction, 3000);
    };
    window.onoffline = (e) => {
      setIsOnline(false);
      const beforeFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: null,
            show: true,
            msg: "No internet connection.",
          },
        });
      };
      const afterFunction = () => {
        return dispatch({
          type: "SET_NOTIFICATION",
          notification: {
            success: false,
            msg: "",
            show: false,
          },
        });
      };
      timer(beforeFunction, afterFunction, 3000);
    };
  }, [isOnline]);

  if (isOnline) {
    return null;
  } else {
    return <FullscreenView flagger={"no-connection-modal"} />;
  }
};

export default CheckInternetConnection;
