import Axios from "../../src/helpers/axios";

export const getUserShippingAddresses = () => {
  return Axios.get("/api/ecommerce/v1/shippingaddress");
};

export const getUserShippingAddressById = (shippingId) => {
  return Axios.get(`/api/ecommerce/v1/shippingaddress/user/id/${shippingId}`)
}

export const createNewShippingAddress = (body) => {
  return Axios.post("/api/ecommerce/v1/shippingaddress/create", body);
};

export const editShippingAddress = (body) => {
  return Axios.put("/api/ecommerce/v1/shippingaddress/edit", body)
}

export const deleteShippingAddress = (shippingId) => {
  return Axios.delete(`/api/ecommerce/v1/shippingaddress/delete/${shippingId}`)
}