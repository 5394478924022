import React, { useState } from "react";
import { useDispatch } from "react-redux";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Styles
import "../../styles/authentication.css";

// Icons and Images
import EmailLogin from "../../assets/authenticationIconsAndImages/EmailLogin.png";
import PasswordLogin from "../../assets/authenticationIconsAndImages/PasswordLogin.png";
import EyePasswordHide from "../../assets/authenticationIconsAndImages/EyePasswordHide.png";
import EyePasswordShow from "../../assets/authenticationIconsAndImages/EyePasswordShow.png";

// Components
import { InputText } from "../../components/Inputs";

// Apis
import { loginUser } from "../../api/authentications";

// Helpers
import { timer } from "../../helpers/timer";
import { validateEmail } from "../../helpers/validateEmail";
import { validatePassword } from "../../helpers/validatePassword";

const Login = (props) => {
  const dispatch = useDispatch();
  const {
    setterOpenFullscreen,
    authenticationState,
    CloseFullscreenBtn,
    isMobileSize,
  } = props;
  const [emailLogin, setEmailLogin] = useState("");
  const [passwordLogin, setPasswordLogin] = useState("");
  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [isEmptyEmail, setIsEmptyEmail] = useState(false);
  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
  const [isEmptyPassword, setIsEmptyPassword] = useState(false);
  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState("");
  const [isUserNotVerifiedYet, setIsUserNotVerifiedYet] = useState(false);
  const [isWrongEmailOrPassword, setIsWrongEmailOrPassword] = useState(false);

  const handleOnClickIconPassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const handleOnChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  const handleLoginValidation = () => {
    let invalidEmail = false;
    let invalidEmailFormat = false;
    let invalidPassword = false;
    let invalidPassMsg = "";

    if (!emailLogin) {
      invalidEmail = true;
    } else {
      if (validateEmail(emailLogin) !== true) {
        invalidEmailFormat = true;
      }
    }
    if (!passwordLogin) {
      invalidPassword = true;
    } else {
      // validate password format
      const isValidPassword = validatePassword(passwordLogin);
      if (!isValidPassword) {
        invalidPassword = true;
        invalidPassMsg =
          "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      }
    }

    setIsEmptyEmail(invalidEmail);
    setInvalidEmailFormat(invalidEmailFormat)
    setIsEmptyPassword(invalidPassword);
    setInvalidPasswordMsg(invalidPassMsg);

    if (invalidEmail !== true && invalidPassword !== true) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnClickLoginBtn = async () => {
    const body = {
      email: emailLogin,
      password: passwordLogin,
    };
    try {
      const isValid = handleLoginValidation();
      if (isValid) {
        const result = await loginUser(body);
        if (result && result.data) {
          const { data } = result || {};
          const beforeFunction = () => {
            return dispatch({
              type: "SET_NOTIFICATION",
              notification: {
                success: true,
                msg: "Successfully login",
                show: true,
              },
            });
          };
          const afterFunction = () => {
            return dispatch({
              type: "SET_NOTIFICATION",
              notification: {
                success: false,
                msg: "",
                show: false,
              },
            });
          };
          timer(beforeFunction, afterFunction, 3000);
          dispatch({
            type: "SET_USER_DETAIL",
            userDetail: data,
          });
          setterOpenFullscreen({
            ...authenticationState,
            register: false,
            login: false,
          });
        }
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { response: { data: { message } = {} } = {} } = err || {};
        if (message) {
          if (message === "user-not-verified") {
            const beforeFunction = () => {
              return setIsUserNotVerifiedYet(true);
            };
            const afterFunction = () => {
              return setIsUserNotVerifiedYet(false);
            };
            timer(beforeFunction, afterFunction, 5000);
          }
          if (message === "wrong-email-or-password") {
            const beforeFunction = () => {
              return setIsWrongEmailOrPassword(true);
            };
            const afterFunction = () => {
              return setIsWrongEmailOrPassword(false);
            };
            timer(beforeFunction, afterFunction, 5000);
          }
        }
      }
    }
  };

  return (
    <div className="loginBoxMainContainer">
      <div
        className="closeBtn"
        style={{ width: "100%", marginRight: isMobileSize ? "2em" : "4em" }}
        onClick={() =>
          setterOpenFullscreen({ ...authenticationState, login: false })
        }
      >
        <img
          src={CloseFullscreenBtn}
          alt="close-fullscreen-btn"
          width={isMobileSize ? 12 : 28}
          height={isMobileSize ? 12 : 28}
        />
      </div>
      <div className="tabNameStyle">Welcome back</div>
      <div className="signInToYourAccountStyle">Sign in to your account</div>
      <div className="loginInputContainer">
        <div>
          <div className="inputLabelWithIcon">
            <img src={EmailLogin} alt="email-icon" />
            <div className="inputLabel">Email</div>
          </div>
          <InputText
            width={"100%"}
            fontSize={isMobileSize ? "14px" : "16px"}
            border={"1px solid #000000"}
            borderRadius={"10px"}
            height={"5vh"}
            placeholder={"Fill in email address"}
            name={"loginEmailAddress"}
            value={emailLogin}
            setter={setEmailLogin}
          />
          {isEmptyEmail && !emailLogin ? (
            <div className="errorMessage">This field is required</div>
          ) : null}
          {invalidEmailFormat ? (
            <div className="errorMessage">Please input a valid email format</div>
          ) : null}
          {isUserNotVerifiedYet ? (
            <div className="errorMessage">Please verify this account first</div>
          ) : null}
          {isWrongEmailOrPassword ? (
            <div className="errorMessage">Wrong email or password</div>
          ) : null}
        </div>
      </div>
      <div className="loginInputContainer">
        <div>
          <div className="inputLabelWithIconPassword">
            <img src={PasswordLogin} alt="password-icon" />
            <div className="inputLabel">Password</div>
          </div>
          <div className="inputPasswordContainer">
            <InputText
              type={passwordInputType}
              width={"100%"}
              fontSize={isMobileSize ? "14px" : "16px"}
              border={"1px solid #000000"}
              borderRadius={"10px"}
              height={"5vh"}
              placeholder={"Fill in password"}
              name={"loginPassword"}
              value={passwordLogin}
              setter={setPasswordLogin}
            />
            <div
              className="loginEyePassword"
              onClick={() => handleOnClickIconPassword()}
            >
              <img
                src={
                  passwordInputType === "password"
                    ? EyePasswordHide
                    : EyePasswordShow
                }
                alt="show-hide-password-icon"
              />
            </div>
          </div>
          {isEmptyPassword && !passwordLogin ? (
            <div className="errorMessage">This field is required</div>
          ) : null}
          {invalidPasswordMsg ? (
            <div className="errorMessage">{invalidPasswordMsg}</div>
          ) : null}
          {isWrongEmailOrPassword ? (
            <div className="errorMessage">Wrong email or password</div>
          ) : null}
        </div>
      </div>
      <div className="loginForgotPassword">
        <p
          onClick={() =>
            setterOpenFullscreen({
              ...authenticationState,
              login: false,
              forgotPassword: true,
            })
          }
        >
          Forgot password?
        </p>
      </div>
      <div
        className="loginCaptchaContainer"
        style={
          isMobileSize
            ? {
              transform: "scale(0.80)",
              transformOrigin: "0 0",
              width: "100%",
            }
            : {}
        }
      >
        <ReCAPTCHA
          sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
          onChange={handleOnChangeCaptcha}
        />
      </div>
      <div
        className="btnSignInContainer"
        style={{
          background:
            !isCaptchaCorrect || !emailLogin || !passwordLogin
              ? "#C4C4C4"
              : "#006787",
        }}
        onClick={() => handleOnClickLoginBtn()}
      >
        Sign in
      </div>
      <div className="authenticationPageNavigation">
        <p>
          Don't have an account yet?{" "}
          <span
            onClick={() =>
              setterOpenFullscreen({
                ...authenticationState,
                login: false,
                register: true,
              })
            }
          >
            Register here
          </span>
        </p>
      </div>
    </div>
  );
};

export default Login;
