import React, { useCallback, useEffect, useRef, useState } from 'react'
import Cookies from 'js-cookie'
import { io } from "socket.io-client";
import { useSelector } from 'react-redux';

import AvatarIcon from './icons/AvatarIcon'
import CloseIcon from './icons/CloseIcon'
import SendIcon from './icons/SendIcon'
import MultiChatBubbleIcon from './icons/MultiChatBubbleIcon'
import ExitIcon from './icons/ExitIcon'

import { createChatMessage, endChat, getChatRoom, getChatRoomByUserId, initUserChatRoom, updateUserName } from './api'
import { getServerURL } from '../../helpers/axios';

import './styles.css'

const socket = io(getServerURL());

function SupportChat() {
    const inputRef = useRef(null)
    const userDetail = useSelector((state) => state.userDetail);
    const storeDetails = useSelector((state) => state.storeDetails);
    const userToken = Cookies.get("gt")
    const sender_id = userDetail?.id || userToken

    const endOfMessageRef = useRef(null)
    const [chatRoom, setChatRoom] = useState([])
    const [newMessage, setNewMessage] = useState("")
    const [loadingReply, setLoadingReply] = useState(false)
    const [isChatOpen, setChatOpen] = useState(false)
    const [isEndChatModalOpen, setEndChatModalOpen] = useState(false)
    const [userName, setUserName] = useState('')
    const [isSubmitting, setSubmitting] = useState(false)
    const [chatRoomId, setChatRoomId] = useState(null)

    const handleClose = useCallback(() => {
        setChatOpen(false)
    }, [])

    const scrollToBottom = useCallback(() => {
        setTimeout(() => endOfMessageRef?.current?.scrollIntoView({ behavior: "instant" }), 50)
    }, [])
    const focusToInput = useCallback(() => {
        setTimeout(() => inputRef?.current?.focus(), 50)
    }, [])


    const handleOpen = useCallback(async () => {
        if (chatRoomId) {
            setLoadingReply(true)
            const _chatRoom = await getChatRoom(chatRoomId);
            setChatRoom(_chatRoom);
            const data = {
                roomId: _chatRoom?.id,
                userId: sender_id,
            }
            socket.emit("join-room", data)
            setLoadingReply(false)
        } else {
            setLoadingReply(true)
            const newChatRoom = await initUserChatRoom();
            setChatRoomId(newChatRoom?.id)
            const _chatRoom = await getChatRoom(newChatRoom?.id);
            setChatRoom(_chatRoom);

            const data = {
                roomId: _chatRoom?.id,
                userId: sender_id,
            }
            socket.emit("join-room", data)
            setLoadingReply(false)
        }
        scrollToBottom()
        setChatOpen(true)
    }, [chatRoomId, sender_id, scrollToBottom])

    const toggleChat = isChatOpen ? handleClose : handleOpen

    const handleEndChat = async () => {
        await endChat(chatRoomId)
        Cookies.remove('ut')
        setChatOpen(false)
        setEndChatModalOpen(false)
        window.location.reload();
    }

    const handleChange = (e) => {
        if (e?.target?.name === "new_message") {
            setNewMessage(e?.target?.value)
        }
    }

    const fetchChatRoom = useCallback(async () => {
        const _chatRoom = await getChatRoom(chatRoomId);
        setChatRoom(_chatRoom);
        scrollToBottom();
    }, [chatRoomId, scrollToBottom])

    const sendMessage = useCallback(async (newMessage) => {
        await createChatMessage(chatRoomId, sender_id, newMessage)
        socket.emit("new-message", chatRoomId, 2, newMessage)
    }, [chatRoomId, sender_id])

    const handleSubmitMessage = async (e) => {
        e.preventDefault();
        if (newMessage) {
            setSubmitting(true)
            setNewMessage("")
            await sendMessage(newMessage)
            await fetchChatRoom()
            setSubmitting(false)
            focusToInput()
        }
    }

    const handleClickReplyOption = (option) => {
        setLoadingReply(true)
        sendMessage(option.text)
        fetchChatRoom()
        setLoadingReply(false)
    }

    const handleOnKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmitMessage(e)
        }
    }

    const handleSaveName = async () => {
        if (userName.length > 1) {
            setSubmitting(true)
            await updateUserName(sender_id, userName)
            await sendMessage(userName)
            await fetchChatRoom()
            setSubmitting(false)
            setUserName("")
            focusToInput()
        }
    }

    const handleOnInputNameKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSaveName()
        }
    }

    useEffect(() => {
        getChatRoomByUserId().then(chatRooms => {
            setChatRoomId(chatRooms?.[0]?.id)
        })
    }, [])

    useEffect(() => {
        socket.on("new-message", (chat_room_id, user_role_id, newMessage) => fetchChatRoom())
        return () => socket.off("new-message")
    }, [fetchChatRoom])

    useEffect(() => {
        socket.on("other-new-message", (chat_room_id, user_role_id, newMessage) => fetchChatRoom())
        return () => socket.off("other-new-message")
    }, [fetchChatRoom])

    useEffect(() => {
        socket.on("bot-new-message", (chat_room_id, user_role_id, newMessages) => fetchChatRoom())
        return () => socket.off("bot-new-message")
    }, [fetchChatRoom])

    const disableInput = chatRoom?.ChatMessages?.[chatRoom?.ChatMessages?.length - 1]?.ChatKeyword?.ChatReplyOptions?.find(option => option?.type === "input") || isSubmitting
    const disableSaveName = userName.length < 2 || isSubmitting

    return (
        <div id="support_chat_root" className='support_chat_root'>
            {isChatOpen && (
                <div className="support_chat_container">
                    <div className="support_chat_inner_container">
                        <div className="support_chat_header">
                            <div className="support_chat_header_main">
                                <p className="support_chat_header_title">
                                    {storeDetails?.shop_name}
                                </p>
                                <div className='support_chat_header_action_group'>
                                    <button className='support_chat_header_action' onClick={() => setEndChatModalOpen(true)}>
                                        <ExitIcon className="support_chat_header_action_primary_icon" />
                                    </button>
                                    <button className='support_chat_header_action' onClick={handleClose}>
                                        <CloseIcon className="support_chat_header_action_secondary_icon" />
                                    </button>
                                </div>
                            </div>
                            <p className="support_chat_header_subtitle">
                                Chat with our customer support
                            </p>
                        </div>

                        <div className="support_chat_body_container">
                            {chatRoom?.ChatMessages?.map((chat, i) => (
                                chat?.sender_id.toString() === (sender_id.toString() || Cookies.get("gt")) ? (
                                    <div key={i} className="support_chat_bubble_me">
                                        <div className='support_chat_bubble_me_text'>
                                            {chat.text}
                                        </div>
                                        <div>
                                            <AvatarIcon className="support_chat_bubble_avatar_icon" />
                                        </div>
                                    </div>
                                ) : (
                                    <div key={i} className="support_chat_bubble_other">
                                        <div>
                                            <AvatarIcon className="support_chat_bubble_avatar_icon" />
                                        </div>
                                        <div className="support_chat_bubble_other_text">
                                            {chat.text}
                                        </div>
                                    </div>
                                )
                            ))}
                            {loadingReply && (
                                <div className="loading_reply_container">
                                    <div>
                                        <AvatarIcon className="support_chat_bubble_avatar_icon" />
                                    </div>
                                    <div className="loading_reply_body">
                                        <span className="loading_reply_item">
                                            <span className="loading_reply_item_upper"></span>
                                            <span className="loading_reply_item_lower"></span>
                                        </span>
                                        <span className="loading_reply_item">
                                            <span className="loading_reply_item_upper"></span>
                                            <span className="loading_reply_item_lower"></span>
                                        </span>
                                        <span className="loading_reply_item">
                                            <span className="loading_reply_item_upper"></span>
                                            <span className="loading_reply_item_lower"></span>
                                        </span>
                                    </div>
                                </div>
                            )}

                            <div className='chat_reply_options'>
                                {chatRoom?.ChatMessages?.[chatRoom?.ChatMessages?.length - 1]?.ChatKeyword?.ChatReplyOptions?.map((option, i) => (
                                    option.type === "input" ? (
                                        <div className='chat_reply_options_input_type'>
                                            <div className='chat_reply_options_input_container'>
                                                <label htmlFor={option.text} className='chat_reply_options_input_label'>{option.text}</label>
                                                <input
                                                    id={option.text}
                                                    className="chat_reply_options_input"
                                                    placeholder={option.text}
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)}
                                                    onKeyDown={handleOnInputNameKeyDown}
                                                    autoFocus
                                                />
                                            </div>
                                            <button
                                                className={`${disableSaveName ? "disabled" : ""} chat_reply_options_send_button`}
                                                onClick={handleSaveName}
                                                disabled={disableSaveName}
                                            >
                                                Send
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            className="chat_reply_options_button_type"
                                            key={i}
                                            onClick={() => handleClickReplyOption(option)}
                                        >
                                            {option.text}
                                        </button>
                                    )
                                ))}
                            </div>
                            <div ref={endOfMessageRef} />
                        </div>

                        <div>
                            <form className="support_chat_input_container">
                                <input
                                    ref={inputRef}
                                    className="support_chat_input"
                                    placeholder="Write your message here"
                                    name="new_message"
                                    value={newMessage}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    autoFocus
                                    onKeyDown={handleOnKeyDown}
                                    disabled={disableInput}
                                />
                                <button
                                    className="support_chat_input_button"
                                    onClick={handleSubmitMessage}
                                    disabled={disableInput}
                                >
                                    <SendIcon className='send_icon' />
                                </button>
                            </form>
                        </div>
                    </div>

                    {isEndChatModalOpen && (
                        <div className='end_chat_modal_container'>
                            <p className='end_chat_modal_title'>End Chat?</p>
                            <p>All your message will be lost</p>
                            <div className='end_chat_modal_action_group'>
                                <button className='end_chat_modal_action_secondary' onClick={() => setEndChatModalOpen(false)}>No</button>
                                <button className='end_chat_modal_action_primary' onClick={handleEndChat}>Yes</button>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <button
                className='support_chat_button'
                onClick={toggleChat}
            >
                {isChatOpen ? (
                    <CloseIcon className="close_icon" />
                ) : (
                    <MultiChatBubbleIcon className="chat_bubble_icon" />
                )}
            </button>
        </div>
    )
}

export default SupportChat;