import React, { useEffect, useState } from "react";

// styles
import "../styles/items.css";

const Pagination = ({
  currentPage,
  totalPages,
  onPageChange,
  ArrowLeftIcon,
  GreyArrowLeftIcon,
  ArrowRightIcon,
  GreyArrowRightIcon,
}) => {
  const generatePageNumbers = () => {
    const maxVisiblePages = 2; // Number of page numbers to display
    const finalPageNumbers = [];

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        finalPageNumbers.push(i);
      }
    } else {
      const halfVisiblePages = Math.floor(maxVisiblePages / 2);
      const startPage = Math.max(currentPage - halfVisiblePages, 1);
      const endPage = Math.min(currentPage + halfVisiblePages, totalPages);

      if (startPage > 1) {
        finalPageNumbers.push(1);
        if (startPage > 2) {
          finalPageNumbers.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        finalPageNumbers.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          finalPageNumbers.push("...");
        }
        finalPageNumbers.push(totalPages);
      }
    }
    return finalPageNumbers;
  };

  let pageNumbers = generatePageNumbers();

  const handlePageChange = (page) => {
    if (page === "...") {
      return;
    }
    onPageChange(page);
  };

  let lastPage =
    pageNumbers &&
    Array.isArray(pageNumbers) &&
    pageNumbers.length > 0 &&
    pageNumbers[pageNumbers.length - 1];

  return (
    <div className="itemPaginationContainer" style={{ listStyle: "none" }}>
      <img
        src={currentPage > 1 ? ArrowLeftIcon : GreyArrowLeftIcon}
        alt="arrow-left-icon"
        className="paginationBtnStyle"
        style={{ marginRight: "0.5em", cursor: "pointer" }}
        onClick={() => (currentPage > 1 ? onPageChange(currentPage - 1) : null)}
      />
      {pageNumbers &&
        Array.isArray(pageNumbers) &&
        pageNumbers.length > 0 &&
        pageNumbers.map((page, index) => {
          return (
            <li
              key={index}
              className="paginationLabel"
              style={{
                fontWeight: page === currentPage ? "700" : "500",
                cursor: "pointer",
              }}
              onClick={() => handlePageChange(page)}
            >
              {page}
            </li>
          );
        })}
      <img
        src={currentPage !== lastPage ? ArrowRightIcon : GreyArrowRightIcon}
        alt="arrow-right-icon"
        className="paginationBtnStyle"
        style={{ marginLeft: "0.5em", cursor: "pointer" }}
        onClick={() =>
          currentPage !== lastPage ? onPageChange(currentPage + 1) : null
        }
      />
    </div>
  );
};

export default Pagination;
