import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Styles
import "../../styles/authentication.css";

// Icons and Images
import EmailLogin from "../../assets/authenticationIconsAndImages/EmailLogin.png";
import PasswordLogin from "../../assets/authenticationIconsAndImages/PasswordLogin.png";
import EyePasswordHide from "../../assets/authenticationIconsAndImages/EyePasswordHide.png";
import EyePasswordShow from "../../assets/authenticationIconsAndImages/EyePasswordShow.png";
import UsernameLogin from "../../assets/authenticationIconsAndImages/UsernameLogin.png";

// Components
import { InputText } from "../../components/Inputs";

// Apis
import { registerUser } from "../../api/authentications";

// Helpers
import { timer } from "../../helpers/timer";
import { validateEmail } from "../../helpers/validateEmail";
import { validatePassword } from "../../helpers/validatePassword";

const Register = (props) => {
  const {
    setterOpenFullscreen,
    authenticationState,
    CloseFullscreenBtn,
    isMobileSize,
  } = props;
  const storeDetails = useSelector((state) => state.storeDetails);

  const dispatch = useDispatch();
  const [usernameRegister, setUsernameRegister] = useState();
  const [emailRegister, setEmailRegister] = useState();
  const [passwordRegister, setRegisterPassword] = useState();
  const [reTypePasswordRegister, setReTypePasswordRegister] = useState();
  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [passwordInputType, setPasswordInputType] = useState("password");

  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
  const [invalidUsername, setInvalidUsername] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidReTypePassword, setInvalidReTypePassword] = useState(false);
  const [
    invalidMatchBetweenPasswordAndRetypePassword,
    setInvalidMatchBetweenPasswordAndRetypePassword,
  ] = useState(false);
  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState("");

  const handleOnClickIconPassword = () => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
    } else {
      setPasswordInputType("password");
    }
  };

  const handleOnChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  const handleValidationRegister = () => {
    let invalidEmail = false;
    let invalidEmailFormat = false;
    let invalidUsername = false;
    let invalidPassword = false;
    let invalidReTypePassword = false;
    let invalidMatchBetweenPasswordAndRetypePassword = false;
    let invalidPassMsg = "";

    if (!usernameRegister) {
      invalidUsername = true;
    }
    if (!emailRegister) {
      invalidEmail = true;
    } else {
      if (validateEmail(emailRegister) !== true) {
        invalidEmailFormat = true;
      }
    }
    if (!passwordRegister || !reTypePasswordRegister) {
      if (!passwordRegister) {
        invalidPassword = true;
        invalidPassMsg = "This field is required";
      }
      if (!reTypePasswordRegister) {
        invalidReTypePassword = true;
      }
    } else {
      // validate password format
      const isValidPassword = validatePassword(passwordRegister);
      if (!isValidPassword) {
        invalidPassword = true;
        invalidPassMsg =
          "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      } else {
        // check if password and retype password not match
        if (passwordRegister !== reTypePasswordRegister) {
          invalidMatchBetweenPasswordAndRetypePassword = true;
        }
      }
    }

    setInvalidEmail(invalidEmail);
    setInvalidEmailFormat(invalidEmailFormat);
    setInvalidUsername(invalidUsername);
    setInvalidPassword(invalidPassword);
    setInvalidReTypePassword(invalidReTypePassword);
    setInvalidMatchBetweenPasswordAndRetypePassword(
      invalidMatchBetweenPasswordAndRetypePassword
    );
    setInvalidPasswordMsg(invalidPassMsg);

    if (
      invalidEmail !== true &&
      invalidEmailFormat !== true &&
      invalidUsername !== true &&
      invalidPassword !== true &&
      invalidReTypePassword !== true &&
      invalidMatchBetweenPasswordAndRetypePassword !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleOnClickRegisterBtn = async () => {
    const isValid = handleValidationRegister();
    if (isValid) {
      const data = {
        email: emailRegister,
        username: usernameRegister,
        password: passwordRegister,
      };
      const result = await registerUser(data);
      if (result && result.data) {
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: true,
              msg: "Register success, verify account link sent to your email.",
              show: true,
            },
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
        setterOpenFullscreen({
          ...authenticationState,
          register: false,
        });
      }
    }
  };

  return (
    <div className="registerBoxMainContainer">
      <div
        className="closeBtn"
        style={{ width: "100%", marginRight: isMobileSize ? "2em" : "3vw" }}
        onClick={() =>
          setterOpenFullscreen({ ...authenticationState, register: false })
        }
      >
        <img src={CloseFullscreenBtn} alt="close-fullscreen-btn" />
      </div>
      <div className="flex-1 flex flex-col items-center w-full overflow-y-auto overflow-x-hidden">
        <div className="tabNameStyle">Create your account</div>
        <div className="signInToYourAccountStyle">Register to {storeDetails?.shop_name}</div>
        <div className="loginInputContainer">
          <div>
            <div className="inputLabelUsernameWithIcon">
              <img src={UsernameLogin} alt="username-icon" />
              <div className="inputLabel">Username</div>
            </div>
            <InputText
              width={"100%"}
              fontSize={isMobileSize ? "14px" : "16px"}
              border={"1px solid #000000"}
              borderRadius={"10px"}
              height={"5vh"}
              placeholder={"Fill in username"}
              name={"registerUsername"}
              value={usernameRegister}
              setter={setUsernameRegister}
            />
            {invalidUsername ? (
              <div className="errorMessage">This field is required</div>
            ) : null}
          </div>
        </div>
        <div className="loginInputContainer">
          <div>
            <div className="inputLabelWithIcon">
              <img src={EmailLogin} alt="email-icon" />
              <div className="inputLabel">Email</div>
            </div>
            <InputText
              width={"100%"}
              fontSize={isMobileSize ? "14px" : "16px"}
              border={"1px solid #000000"}
              borderRadius={"10px"}
              height={"5vh"}
              placeholder={"Fill in email address"}
              name={"registerEmailAddress"}
              value={emailRegister}
              setter={setEmailRegister}
            />
            {invalidEmail || invalidEmailFormat ? (
              <div className="errorMessage">
                {invalidEmail
                  ? "This field is required"
                  : "Please input a valid email format"}
              </div>
            ) : null}
          </div>
        </div>
        <div className="loginInputContainer">
          <div>
            <div className="inputLabelWithIconPassword">
              <img src={PasswordLogin} alt="password-icon" />
              <div className="inputLabel">Password</div>
            </div>
            <div className="inputPasswordContainer">
              <InputText
                type={passwordInputType}
                width={"100%"}
                fontSize={isMobileSize ? "14px" : "16px"}
                border={"1px solid #000000"}
                borderRadius={"10px"}
                height={"5vh"}
                placeholder={"Fill in password"}
                name={"registerPassword"}
                value={passwordRegister}
                setter={setRegisterPassword}
              />
              <div
                className="loginEyePassword"
                onClick={() => handleOnClickIconPassword()}
              >
                <img
                  src={
                    passwordInputType === "password"
                      ? EyePasswordHide
                      : EyePasswordShow
                  }
                  alt="show-hide-password-icon"
                />
              </div>
            </div>
            {invalidPassword && invalidPasswordMsg ? (
              <div className="errorMessage">{invalidPasswordMsg}</div>
            ) : null}
          </div>
        </div>
        <div className="loginInputContainer">
          <div>
            <div className="inputLabelWithIconPassword">
              <img src={PasswordLogin} alt="password-icon" />
              <div className="inputLabel">Retype Password</div>
            </div>
            <div className="inputPasswordContainer">
              <InputText
                type={passwordInputType}
                width={"100%"}
                fontSize={isMobileSize ? "14px" : "16px"}
                border={"1px solid #000000"}
                borderRadius={"10px"}
                height={"5vh"}
                placeholder={"Re-type password"}
                name={"registerPassword"}
                value={reTypePasswordRegister}
                setter={setReTypePasswordRegister}
              />
              <div
                className="loginEyePassword"
                onClick={() => handleOnClickIconPassword()}
              >
                <img
                  src={
                    passwordInputType === "password"
                      ? EyePasswordHide
                      : EyePasswordShow
                  }
                  alt="show-hide-password-icon"
                />
              </div>
            </div>
            {invalidReTypePassword ||
              invalidMatchBetweenPasswordAndRetypePassword ? (
              <div className="errorMessage">
                {invalidReTypePassword
                  ? "This field is required"
                  : "Password did not match"}
              </div>
            ) : null}
          </div>
        </div>
        <div
          className="loginCaptchaContainer"
          style={
            isMobileSize
              ? {
                transform: "scale(0.80)",
                transformOrigin: "0 0",
                width: "100%",
              }
              : {}
          }
        >
          <ReCAPTCHA
            style={{ marginTop: "3vh" }}
            sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
            onChange={handleOnChangeCaptcha}
          />
        </div>
      </div>
      <div
        className="btnSignInContainer"
        style={{
          background:
            !isCaptchaCorrect ||
              !usernameRegister ||
              !emailRegister ||
              !passwordRegister ||
              !reTypePasswordRegister
              ? "#C4C4C4"
              : "#0d4c86",
        }}
        onClick={() =>
          !isCaptchaCorrect ||
            !usernameRegister ||
            !emailRegister ||
            !passwordRegister ||
            !reTypePasswordRegister
            ? null
            : handleOnClickRegisterBtn()
        }
      >
        <div>Register Account</div>
      </div>
      <div className="authenticationPageNavigation">
        <p>
          Already have an account?{" "}
          <span
            onClick={() =>
              setterOpenFullscreen({
                ...authenticationState,
                login: true,
                register: false,
              })
            }
          >
            Login here
          </span>
        </p>
      </div>
    </div>
  );
};

export default Register;
