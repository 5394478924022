import { createStore } from "redux";

const initialState = {
  showPopUpBanner: false,
  userDetail: null,
  searchState: {
    currencyVal: {
      label: "IDR (Indonesian Rupiah)",
      code: "IDR",
      name: "Indonesian Rupiah",
      id: 23,
    },
  },
  paramsTokenResetPassword: "",
  guestToken: "",
  authenticationModal: {
    login: false,
    register: false,
    forgotPassword: false,
    resetPassword: false,
    tokenResetPassword: null,
  },
  notification: {
    msg: "",
    success: null,
    show: false,
  },
  isMobileSize: false,
  products: [],
  categories: [],
  shoppingCarts: [],
  totalUsersCart: null,
  productListPageState: {
    totalPages: null,
    currentPage: 1,
    limit: 12,
  },
  isOpenCookieConsent: true,
  wishlist: [],
  skeletonLoader: false,
  isLoading: false,
  isSuccessVerifyUser: false,
  isSuccessRequestResetPassword: false,
  isSuccessResetPassword: false,
  storeDetails: {},
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "SET_SEARCH_STATE":
      return { ...state, ...rest };
    case "SET_USER_DETAIL":
      return { ...state, ...rest };
    case "SET_TOKEN_RESET_PASSWORD":
      return { ...state, ...rest };
    case "SET_SHOW_POP_UP_BANNER":
      return { ...state, ...rest };
    case "SET_GUEST_TOKEN":
      return { ...state, ...rest };
    case "SET_AUTHENTICATION_MODAL":
      return { ...state, ...rest };
    case "SET_NOTIFICATION":
      return { ...state, ...rest };
    case "SET_IS_MOBILE_SIZE":
      return { ...state, ...rest };
    case "SET_PRODUCTS":
      return { ...state, ...rest };
    case "SET_CATEGORIES":
      return { ...state, ...rest };
    case "SET_USERS_CART":
      return { ...state, ...rest };
    case "SET_TOTAL_USERS_CART":
      return { ...state, ...rest };
    case "SET_PRODUCT_LIST_PAGE_STATE":
      return { ...state, ...rest };
    case "SET_SHOW_MODAL_COOKIES_CONSENT":
      return { ...state, ...rest };
    case "SET_WISHLIST":
      return { ...state, ...rest };
    case "SET_SKELETON_LOADER":
      return { ...state, ...rest };
    case "SET_IS_LOADING":
      return { ...state, ...rest };
    case "SET_IS_SUCCESS_VERIFY_USER":
      return { ...state, ...rest };
    case "SET_IS_SUCCESS_REQUEST_RESET_PASSWORD":
      return { ...state, ...rest };
    case "SET_IS_SUCCESS_RESET_PASSWORD":
      return { ...state, ...rest };
    case "SET_STORE_DETAILS":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
