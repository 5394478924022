import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Styles
import "../../styles/authentication.css";

// Icons and Images
import EmailLogin from "../../assets/authenticationIconsAndImages/EmailLogin.png";

// Components
import { InputText } from "../../components/Inputs";

// Apis
import { requestForgotPassword } from "../../api/authentications";

// Helpers
import { timer } from "../../helpers/timer";
import { validateEmail } from "../../helpers/validateEmail";

const ForgotPassword = (props) => {
  const {
    setterOpenFullscreen,
    authenticationState,
    CloseFullscreenBtn,
    isMobileSize,
  } = props;
  const dispatch = useDispatch();
  const authenticationModal = useSelector((state) => state.authenticationModal);

  const [emailForgotPassword, setEmailForgotPassword] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmailFormat, setInvalidEmailFormat] = useState(false);
  const [notValidUser, setNotValidUser] = useState(false);

  const handleValidation = () => {
    let invalidEmail = false;
    let invalidEmailFormat = false;

    if (!emailForgotPassword) {
      invalidEmail = true;
    } else {
      if (validateEmail(emailForgotPassword) !== true) {
        invalidEmailFormat = true;
      }
    }

    setInvalidEmail(invalidEmail);
    setInvalidEmailFormat(invalidEmailFormat);

    if (invalidEmail !== true && invalidEmailFormat !== true) {
      setNotValidUser(false);
      return true;
    } else {
      return false;
    }
  };

  const handleOnClickBtnSubmit = async () => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    try {
      const isValid = handleValidation();
      if (isValid) {
        const result = await requestForgotPassword(emailForgotPassword);
        if (result && result.data) {
          setEmailForgotPassword("");
          const { data: { success } = {} } = result || {};
          if (success) {
            dispatch({
              type: "SET_AUTHENTICATION_MODAL",
              authenticationModal: {
                ...authenticationModal,
                forgotPassword: false,
              },
            });
            const beforeFunction = () => {
              return dispatch({
                type: "SET_IS_SUCCESS_REQUEST_RESET_PASSWORD",
                isSuccessRequestResetPassword: true,
              });
            };
            const afterFunction = () => {
              return dispatch({
                type: "SET_IS_SUCCESS_REQUEST_RESET_PASSWORD",
                isSuccessRequestResetPassword: false,
              });
            };
            timer(beforeFunction, afterFunction, 3000);
          }
        }
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { response: { data: { message } = {} } = {} } = err || {};
        if (message === "user-not-found") {
          setNotValidUser(true);
        }
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  return (
    <div className="forgotPasswordBoxMainContainer">
      <div
        className="closeBtn"
        style={{ width: "100%", marginRight: isMobileSize ? "2em" : "4em" }}
        onClick={() =>
          setterOpenFullscreen({
            ...authenticationState,
            forgotPassword: false,
          })
        }
      >
        <img
          src={CloseFullscreenBtn}
          alt="close-fullscreen-btn"
          width={isMobileSize ? 12 : 28}
          height={isMobileSize ? 12 : 28}
        />
      </div>
      <div className="tabNameStyle">Forgot password</div>
      <div className="descForgotPasswordStyle">
        Please enter your email address below to receive your password reset instructions
      </div>
      <div
        className="loginInputContainer"
        style={{ marginTop: "2em", marginLeft: "0.1vw" }}
      >
        <div>
          <div className="inputLabelWithIcon">
            <img src={EmailLogin} alt="email-icon" />
            <div className="inputLabel">Email</div>
          </div>
          <InputText
            width={isMobileSize ? "100%" : "100%"}
            fontSize={isMobileSize ? "14px" : "16px"}
            border={"1px solid #000000"}
            borderRadius={"10px"}
            height={"5vh"}
            placeholder={"Fill in email address"}
            name={"loginEmailAddress"}
            value={emailForgotPassword}
            setter={setEmailForgotPassword}
          />
          {invalidEmail || invalidEmailFormat || notValidUser ? (
            <div className="errorMessage">
              {invalidEmail
                ? "This field is required"
                : notValidUser
                ? "User's not valid"
                : "Please input a valid email format"}
            </div>
          ) : null}
        </div>
      </div>
      <div
        className="btnSignInContainer"
        style={{
          background: !emailForgotPassword ? "#C4C4C4" : "#006787",
          marginTop: isMobileSize ? "1.5em" : "2em",
        }}
        onClick={() => handleOnClickBtnSubmit()}
      >
        <div>Submit</div>
      </div>
    </div>
  );
};

export default ForgotPassword;
