// Helpers
import { formUrlQuery } from "./format-queries";

// Apis
import { getAllProductsInCart } from "../api/carts";

export const handleFetchUsersShoppingCarts = async (searchState, dispatch) => {
  let queries = {};
  if (
    searchState &&
    searchState.currencyVal &&
    searchState.currencyVal.id &&
    searchState.currencyVal.id !== 32
  ) {
    queries.currency_id = searchState.currencyVal.id;
  }

  queries = formUrlQuery(queries);
  const result = await getAllProductsInCart(queries);
  if (result && result.data) {
    const { data } = result || {};
    let finalShoppingCarts = data;
    let totalUsersCart = 0;
    finalShoppingCarts =
      finalShoppingCarts &&
        Array.isArray(finalShoppingCarts) &&
        finalShoppingCarts.length > 0
        ? finalShoppingCarts.map((cart) => {
          totalUsersCart += cart.qty;
          cart.checked = true;
          cart.onMouseOverPlus = false;
          cart.onMouseOverMinus = false;
          return cart;
        })
        : [];
    dispatch({
      type: "SET_USERS_CART",
      shoppingCarts: finalShoppingCarts,
    });
    dispatch({
      type: "SET_TOTAL_USERS_CART",
      totalUsersCart,
    });
  }
};
