import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// Cookies
import Cookies from "js-cookie";

// Apis
import { getUserProfile } from "../api/authentications";

const LoginGuard = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = Cookies.get("ut");

  const handleGetUsersProfile = async () => {
    try {
      const result = await getUserProfile();
			if (result && result.data) {
				dispatch({ type: "SET_USER_DETAIL", userDetail: result.data });
			} else {
				navigate("/");
			}
    } catch (err) {
      navigate("/");
    }
  };

  useEffect(() => {
    if (token) {
      handleGetUsersProfile();
    } else {
      navigate("/");
    }
  }, [token]);

  if (!token) {
    navigate("/");
  } else {
    return children;
  }
};

export default LoginGuard;
