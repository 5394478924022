import React from "react";

const SearchResult = ({ text, searchKey, className }) => {
  const regex = new RegExp(searchKey, "gi");

  const highlightedText = text.replace(
    regex,
    (match) => `<strong>${match}</strong>`
  );

  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: highlightedText }}
    />
  );
};

export default SearchResult;
