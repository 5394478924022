// Apis
import { getAllProductCount, getAllProducts } from "../api/products";

export const handleFetchAllProducts = async (queries, isProductCounter) => {
  const result = isProductCounter
    ? await getAllProductCount(queries)
    : await getAllProducts(queries);
  if (result && result.data) {
    const { data } = result || {};
    return data;
  }
};
