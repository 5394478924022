import React from "react";

// Images and Icons
import EmptyWishlistIcon from "../../../assets/myProfileIcons/EmptyWishlistIcon.png";
import DeleteProductIcon from "../../../assets/itemImagesAndIcons/DeleteProductIcon.png";

// Apis
import { deleteWishlist } from "../../../api/wishlist";

// Helpers
import { timer } from "../../../helpers/timer";
import { formatPrice } from "../../../helpers/format-price";

const WishlistTab = ({ dispatch, isMobileSize, searchState, wishlist }) => {
  const handleOnClickDeleteWishlist = async (wishlistId) => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    const result = await deleteWishlist(wishlistId);
    if (result && result.data) {
      const { data } = result || {};
      if (data && data.is_deleted) {
        let finalArrWishlist = wishlist;
        finalArrWishlist = finalArrWishlist.filter(
          (wishlist) => wishlist.id !== wishlistId
        );
        dispatch({
          type: "SET_WISHLIST",
          wishlist: finalArrWishlist,
        });
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: true,
              msg: "Successfully removed item from wishlist",
              show: true,
            },
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  return (
    <div className="tabMainContainer">
      <div className="tabNameContainer">
        {!isMobileSize ? <div className="tabNameStyle">Wishlist</div> : null}
        {wishlist && Array.isArray(wishlist) && wishlist.length > 0 && (
          <div style={{ fontSize: isMobileSize ? "14px" : "16px" }}>
            {wishlist.length > 1
              ? `${wishlist.length} items`
              : `${wishlist.length} item`}
          </div>
        )}
      </div>
      {wishlist && Array.isArray(wishlist) && wishlist.length > 0 ? (
        <div className="wishlistContainer">
          {wishlist.map((wishlist, index) => {
            const { id, product } = wishlist || {};
            let img_url;
            if (
              product &&
              product.images &&
              Array.isArray(product.images) &&
              product.images.length > 0
            ) {
              img_url = product.images[0] && product.images[0].image_url;
            }
            return (
              <div key={index} className="eachWishlistContainer">
                <div className="eachItemContentImgContainer" onClick={() => window.location.assign(`items/${product?.id}`)}>
                  <img src={img_url} alt={`Each-item-${index + 1}`} />
                </div>
                {isMobileSize ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {product && product.name ? (
                      <div className="eachWishlistProductNameAndPrice">
                        {product.name}
                      </div>
                    ) : null}
                    <div className="eachWishlistProductNameAndPrice">{`${searchState &&
                      searchState.currencyVal &&
                      searchState.currencyVal.code
                      } ${product && product.price && formatPrice(product.price)
                      }`}</div>
                  </div>
                ) : (
                  <>
                    {product && product.name ? (
                      <div className="eachWishlistProductNameAndPrice">
                        {product.name}
                      </div>
                    ) : null}
                    <div className="eachWishlistProductNameAndPrice">{`${searchState &&
                      searchState.currencyVal &&
                      searchState.currencyVal.code
                      } ${product && product.price && formatPrice(product.price)
                      }`}</div>
                  </>
                )}
                <div className="eachShopNowBtnAndDeleteIconContainer">
                  <div className="eachShopNowBtnContainer">
                    <button onClick={() => window.location.assign(`items/${product?.id}`)}>Shop now</button>
                  </div>
                  <img
                    src={DeleteProductIcon}
                    alt="delete-icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleOnClickDeleteWishlist(id)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="emptyWishlistContainer">
          <img src={EmptyWishlistIcon} alt="empty-wishlist-icon" />
          <div>No items yet in your wishlist. Start shopping now!</div>
        </div>
      )}
    </div>
  );
};

export default WishlistTab;
