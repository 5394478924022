import Axios from "../../src/helpers/axios";

export const fetchAllPromo = () => {
  return Axios.get("/api/ecommerce/v1/promotions/for/users");
};

export const checkPromo = async (body) => {
  try {
    const result = await Axios.post("/api/ecommerce/v1/promotions/add/user", body);
    return result
  } catch (err) {
    return err && err.response && err.response.data
  }
};
