import React, { useEffect, useState } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import moment from "moment"

// Styles
import "../../styles/shoppingCart.css";

// Icon and Images
import ValidIcon from "../../assets/shoopingCartImagesAndIcons/ValidIcon.png";

// Apis
import { getTransactionByOrderId } from "../../api/transactions";
import { formatPrice } from "../../helpers/format-price";

const Payment = () => {
  const navigate = useNavigate();
  const searchState = useSelector((state) => state.searchState);

  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get('order_id');
  const statusCode = urlParams.get('status_code');
  const transactionStatus = urlParams.get('transaction_status');

  const [orderDetail, setOrderDetail] = useState(null);
  const [isLoading, setLoading] = useState(true);

  const fetchOrderDetail = async (orderId) => {
    setLoading(true)
    const result = await getTransactionByOrderId(orderId);
    if (result && result.data) {
      const { data } = result || {};
      if (data && data.id) {
        setOrderDetail(data);
      }
    }
    setLoading(false)
  };

  useEffect(() => {
    if (orderId) {
      fetchOrderDetail(orderId)
    }
  }, [orderId])

  if (!orderId || !transactionStatus) {
    return (
      <Navigate to="/my-orders" />
    )
  }

  if (isLoading || ["capture", "settlement", "success"]?.includes(orderDetail?.payment_status?.toLowerCase())) {
    return (
      <div className="shoppingCartSuccessPaymentMainContainer">
        <div className="successPaymentIconContainer">
          <img src={ValidIcon} alt="valid-icon" />
        </div>
        <div className="successPaymentBoxContainer">
          <div className="successPaymentContentContainer">
            <div className="thankyouForYourOrderStyle">
              Thank you for your order
            </div>
            <div className="thankyouForYourOrderDescStyle">
              The order confirmation has been sent to your email address
            </div>
            <div className="orderIdContainer">
              <div className="orderIdLabel">ORDER ID</div>
              <div className="orderIdBox">
                <div className="whitespace-nowrap">{orderId}</div>
              </div>
            </div>
            <div className="thankyouForYourOrderDivider">
              <div></div>
            </div>
            <div className="orderDescContainer">
              <div className="orderDescContentContainer">
                <div className="eachOrderDescContainer">
                  <div className="text-left" style={{ fontWeight: "500" }}>Date</div>
                  {orderDetail?.createdAt && (
                    <div className="text-right">
                      {orderDetail?.createdAt ? moment(orderDetail?.createdAt).format("DD/MM/YYYY") : "-"}
                    </div>
                  )}
                </div>
                <div className="eachOrderDescContainer">
                  <div className="text-left" style={{ fontWeight: "500" }}>Time</div>
                  <div className="text-right">
                    {orderDetail?.createdAt ? moment(orderDetail?.createdAt).format("HH:mm") : "-"}
                  </div>
                </div>
                <div className="eachOrderDescContainer">
                  <div className="text-left" style={{ fontWeight: "500" }}>Payment method</div>
                  <div className="text-right">
                    {orderDetail?.payment_channel?.name || "-"}
                  </div>
                </div>
                <div className="eachOrderDescContainer">
                  <div className="text-left" style={{ fontWeight: "500" }}>Amount</div>
                  <div className="text-right">

                    {orderDetail?.transaction_amount ? `${searchState?.currencyVal?.code} ${formatPrice(orderDetail?.transaction_amount)}` : "-"}
                  </div>
                </div>
              </div>
            </div>
            <div className="pleaseScreenshotStyle">
              Please screenshot this page as your payment reference
            </div>
          </div>
        </div>
        <div className="buttonMainContainer">
          <button className="btnContinueShopping">
            <div onClick={() => navigate("/")} style={{ color: "#FFF" }}>Continue shopping</div>
          </button>
          <button className="bg-white border border-black p-2 text-sm sm:text-base my-2 w-full sm:w-auto sm:min-w-[300px] sm:h-[45px] flex items-center justify-center">
            <div className="text-black" onClick={() => navigate("/my-orders")}>Back to Order List</div>
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <Navigate to="/my-orders" />
    )
  }


};

export default Payment;
