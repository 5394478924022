import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// Components
import LandingPage from "../LandingPage";

// Apis
import { verifyUserAccount } from "../../api/authentications";

// Helpers
import { timer } from "../../helpers/timer";

const VerifyUserAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { verifyUserToken } = useParams();

  const [hasRunEffect, setHasRunEffect] = useState(false);

  const calledOnce = useRef(false);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }

    if (hasRunEffect === false) {
      dispatch({
        type: "SET_SHOW_POP_UP_BANNER",
        showPopUpBanner: false,
      });
      handleVerifyUserAccount(verifyUserToken);
      setHasRunEffect(true);
      calledOnce.current = true;
    }
  }, []);

  const handleVerifyUserAccount = async (token) => {
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: true,
    });
    try {
      const result = await verifyUserAccount(token);
      if (result && result.data) {
        const { data } = result || {};
        dispatch({
          type: "SET_IS_SUCCESS_VERIFY_USER",
          isSuccessVerifyUser: true,
        });
        dispatch({
          type: "SET_USER_DETAIL",
          userDetail: data,
        });
      }
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { response: { data: { message, success } = {} } = {} } =
          err || {};
        let finalMsg = "";
        if (message === "invalid-token") {
          finalMsg = "Invalid token";
        } else if (message === "user-verified-before") {
          finalMsg = "This account was verified before, please try to login.";
        } else if (message === "error-veify-user") {
          finalMsg = "Verify error";
        }
        const beforeFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success,
              msg: finalMsg,
              show: true,
            },
          });
        };
        const afterFunction = () => {
          return dispatch({
            type: "SET_NOTIFICATION",
            notification: {
              success: false,
              msg: "",
              show: false,
            },
          });
        };
        timer(beforeFunction, afterFunction, 3000);
        navigate("/");
      }
    }
    dispatch({
      type: "SET_IS_LOADING",
      isLoading: false,
    });
  };

  return <LandingPage />;
};

export default VerifyUserAccount;
